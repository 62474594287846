import { Button, Checkbox, Chip } from "@material-ui/core";
import { RefreshOutlined } from "@material-ui/icons";
import { IconDownload } from "icons";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import FormatService from "services/formatService";
import { vehiclePageStyles } from "../vehiclePageStyle";
import { useGetMyReportsQuery } from "./reportSlice";

const MyReportsGrid = () => {
    let { data: reports, refetch: refetchReports, isFetching: isFetchingReports } = useGetMyReportsQuery();
    const classes = vehiclePageStyles();

    const isLoading = () => isFetchingReports; 

    const downloadFile = (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const URLBody = (product) => {
        return <Button variant="contained" color="success" onClick={() => downloadFile(product.url)} ><IconDownload /></Button>
    };

    const renderSentEmail = (product) => {
        return <Checkbox disabled checked={product.emailSent} />
    };

    const renderReportType = (product) => {
        if (product.reportType == 1){
            return <Chip label="Asset Short Report." color="#0090fe" variant="outlined" />
        } 
        if (product.reportType == 2){
            return <Chip label="Asset Everything on the page Report." color="#0090fe" variant="outlined" />
        } 
        return <Chip label="Asset Large Report." color="#0090fe" variant="outlined" />
    };

    const renderCreatedDate = (product) => {
        return <Chip label={FormatService.formatDateAndTime(product.createdDate)} color="secondary"/>
    };

    const header = (
        <Button variant="contained" endIcon={<RefreshOutlined />} onClick={() => refetchReports()}>Refresh </Button>
    );

    return (
        <>
            <div className="card">
                <DataTable value={reports} tableStyle={{ minWidth: '50rem' }} paginator rows={7} dataKey="id" style={{backgroundColor: "#0090fe"}} loading={isLoading()}>
                    <Column header="Report"  body={renderReportType} align="left"></Column>
                    <Column sortable body={renderCreatedDate} header="Create Date" align="center"></Column>
                    <Column field="fileName"  header="File Name" align="left"></Column>
                    <Column header="Email Sent"  body={renderSentEmail} align="center"></Column>
                    <Column header={header} body={URLBody} align="center"></Column>
                </DataTable>
            </div>
        </>
    )
}

export default MyReportsGrid;