import { Checkbox, FormControlLabel } from "@material-ui/core"

export const MultipleChoiceComponent = ({ value, values, onChange }) => {

    const checked = (val) => {
        return !!valueObj?.find(v => v.value == val.value);
    }

    const onChangeValue = (val, checked) => {
        if (checked) {
            let valueList = [...valueObj, val];
            onChange(JSON.stringify(valueList), "values");
            
        }
        else {
            let valueList = [...valueObj.filter(s => s.value != val.value)];
            onChange(JSON.stringify(valueList), "values");
        }
    }

    let valueObj = value ? JSON.parse(value) : [];

    return (
        <>
            {values?.map((val, index) => (
                <div>
                    <FormControlLabel
                        control={<Checkbox />}
                        label={val.name}
                        checked={checked(val)}
                        onChange={(e, checked) => onChangeValue(val, checked)}
                    />
                </div>
            ))}
        </>
    )
}