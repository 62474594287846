import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import accordionCoreStyles from "./accordionCoreStyles";

const useStyles = accordionCoreStyles;
const AccordionCore = ({ child, headerActions , headerActionsPosition = "flex-end", headerText, headerTextIcon  }) =>{
  const classes = useStyles();
  const [accordionOpen, setAccordionOpen] = useState(false);

  return(<Accordion className={classes.accordionBackground} expanded={accordionOpen}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ cursor: 'pointer' }}
            onClick={() => setAccordionOpen(!accordionOpen)} />
        }
        sx={{ cursor: 'unset !important' }}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div className={classes.accordionHeader} onClick={() => setAccordionOpen(!accordionOpen)}>
          <div >
            {headerTextIcon}
          </div>
          <div>
            <span className={classes.accordionHeaderText}> {headerText ?? "---"}</span>
          </div>
        </div>
        <Grid container direction="row" alignItems="center" justifyContent={headerActionsPosition}>
            {headerActions}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {child}
      </AccordionDetails>
    </Accordion>);
}
export default AccordionCore;