import { makeStyles } from '@material-ui/core/styles';

export const tableFiltersStyle = makeStyles((theme) => ({
    filtersContainer: {
        height: '100%',
        minWidth:'200px',
        padding: '1.2rem',
        flex: 1,
        overflowY: 'auto',
        width: '100%'
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        marginLeft: '0.5em'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '1rem',
        marginBottom: '1rem',
        borderBottom: '1px solid #42a5f5',
        alignItems: 'center'
    },
    filtersList: {
        minHeight: '68vh',
        // maxHeight: '68vh',
        paddingRight: '0.5rem',
        flex: 1,
        overflowY: 'auto',
    },
    clearBtn: {
        fontSize: 12,
        padding: 0
    },
    clearBtnContainer: {
        display: 'flex',
    },
    itemWrapper: {
        paddingBottom: '0.5em 0',
        marginBottom: '1em'
    },
    itemHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        alignItems: 'center',
        borderRadius: '5px',
        marginBottom: '0.5rem',
        fontWeight: 600,
        fontSize: 12
    },
    radioLabel: {
        margin: '0.5rem 0',
        width: '200',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 12,
        display: 'flex'
    },
    radioName: {
        padding: '0 0.5rem',
        fontSize: 12,
    },
    filter: {
        // maxHeight: 350,
        // flex: 1,
        // overflowY: 'auto',
    },
    checkbox: {
        padding: '0',
        paddingLeft: '0.7rem'
    },
    showMoreBtn: {
        fontSize: 12,
    },
    applyBtn: {
        margin: '1rem 0',
        width: '100%',
        textAlign: 'center'
    },
    itemTitle: {
        width: '100%'
    },
    openFiltersBadge: {
        // position: 'absolute',
        // left: '0',
        // top: '0',
        // zIndex: 9999,
        // color: 'green'
    },
    badge: {
        backgroundColor: '#ff5555'
    },
    openFiltersBtn: {
        borderRadius: '50%',
        height: '3em',
        width: '3em',
        minWidth: '3em',
        padding: 0
    },
    collapseIcon: {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '4px',
        padding: '0.5em',
        width: '0.8em',
        height: '0.8em',
        marginLeft: '1em'
    },
    filterIconWhite: {
        '& path': {
            stroke: theme.palette.primary.main,
        },
        '& svg': {
            stroke: theme.palette.primary.main,
        }
    },
    groupHeader: {
        cursor: 'pointer',
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 0',
    },
    icon: {
        fontSize: '1.2rem',
        marginLeft: theme.spacing(1),
        verticalAlign: 'middle',
    },
    showOnlyPMFlotsLabel: {
        fontSize: '1.2rem',
        fontWeight: 500,
        color: theme.palette.primary.main,
        paddingBottom: 3
    }
}));
