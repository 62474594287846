import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import Authorize from '../../../../components/Authorize';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';
import { useUpdateClientMutation } from '../../clientConfigSlice';
import EditContractPopupStyle from './EditContractPopupStyle';
import { clientConfigSetup } from '../../clientConfigsSetup';
import NotificationProvider from '../../../../components/NotificationProvider';

const useStyles = EditContractPopupStyle;

const EditContractPopup = ({ contract, open, setOpen, clientConfig, setClientConfig, name }) => {

    const classes = useStyles();
    let [isSaving, setIsSaving] = useState(false);

    let [updateClient] = useUpdateClientMutation();

    const handleClose = () => {
        setClientConfig({ ...clientConfig, changed: false });
        setOpen(false);
    };

    const handleSave = async () => {
        const { clientLots, ...other } = clientConfig;
        setIsSaving(true);
        let result = await updateClient({ ...other, configToUpdate: contract.configType });
        if (result?.error) {
            NotificationProvider.error('Failed to update the contract');
        } else {
            NotificationProvider.success('Successfully updated the contract');
            handleClose();
        }
        setIsSaving(false);
    };

    const onConfigChange = (configName, key, val) => {
        if (configName) {
            setClientConfig({ ...clientConfig, changed: true, ["config"]: { ...clientConfig.config, [configName]: { ...clientConfig.config[configName], [key]: val } } });
        } else {
            setClientConfig({ ...clientConfig, changed: true, [key]: val });
        }
    }

    const allConfigs = clientConfigSetup(clientConfig, onConfigChange)

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>
                <Grid container justifyContent='space-between'>
                    <div>{`${contract?.label} SLA`}</div>
                    {contract?.headerComponent}
                </Grid>
            </DialogTitle>
            <DialogContent>
                {isSaving && <CircularProgress className={classes.spinner} />}
                <Paper className={classes.formContainer}>
                    {allConfigs.map(config => {
                        {
                            if (config.configName === name) {
                                return (
                                    config.component
                                )
                            }
                        }
                    }
                    )}
                </Paper>
            </DialogContent>
            <DialogActions>
                <div className={`${classes.btnContainer}`}>
                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_CHANGE}>
                        <div className={classes.btnWrapper}>
                            <Button
                                onClick={handleClose}
                                className={classes.button}
                                variant="contained"
                            >
                                Close
                            </Button>
                        </div>
                        {clientConfig?.changed && <div className={classes.btnWrapper}>
                            <Button
                                onClick={handleSave}
                                disabled={isSaving}
                                className={classes.button}
                                variant="contained"
                                color="primary"
                            >
                                {isSaving ? "Saving..." : "Save"}
                            </Button>
                        </div>}
                    </Authorize>
                </div>
            </DialogActions>
        </Dialog>

    );
};

export default EditContractPopup;


