import CreateLoadGroup from "features/loads/components/CreateLoadGroup";
import { useState } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core";
import { useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";

function CreateLoadFromAssetDetails({ client, lot, vehicleDetails }) {
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);
    clientsWithConfigs = clientsWithConfigs || [];

    let clientConfig = clientsWithConfigs?.find(c => c.id == client?.id);
    let isUsingTransport = clientConfig?.config.transportConfig.useTransport;

    const [createLoad, setCreateLoad] = useState(false);

    let loadToCreate = {
        'clientId': client?.id,
        'pickupLocationId': lot?.id,
    };

    let vehicle = {
        'vin': vehicleDetails?.descriptor,
        'assetId': vehicleDetails?.assetId,
        'make': vehicleDetails?.make,
        'model': vehicleDetails?.model,
        'year': vehicleDetails?.year,
        'assetTypeId': vehicleDetails?.vehicleTypeId,
        'vehicleFuelTypeId': vehicleDetails?.fuelType,
        'vehicleId': vehicleDetails?.id,
    };

    return (
        <>
            {isUsingTransport ?
                <Grid item>
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={() => setCreateLoad(true)}
                    >
                        Create Load
                    </Button>
                </Grid>
                : null}
            <Dialog open={createLoad} maxWidth='xl' fullWidth>
                <DialogTitle> <Grid container justifyContent="space-between">
                    Create Load
                    <IconButton onClick={() => setCreateLoad()}>
                        <CloseIcon />
                    </IconButton>
                </Grid></DialogTitle>
                <DialogContent>
                    <CreateLoadGroup
                        allowMultileg={false}
                        loadData={loadToCreate}
                        vehicleData={vehicle}
                        onClose={setCreateLoad}
                        disableAssetDetails={true}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreateLoadFromAssetDetails