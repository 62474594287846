import { List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Popover } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useGetAllActivitiesQuery, useGetAllActivityItemsQuery } from 'features/activity/activitySlice';
import { IconSettings } from 'icons';
import { orderBy } from 'lodash';
import moment from "moment";
import React, { useState } from 'react';
import FormatService from 'services/formatService';
import { arrayToObject } from 'utils/object-util';
import Authorize from '../../../../components/Authorize';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import usePrompt from '../../../../hooks/usePrompt';
import statuses from '../../loadStatusesWithIcons';
import { useDeleteStatusMutation } from '../../loadsSlice';
import LoadStatus from '../LoadStatus';
import LoadStatusUpdate from '../LoadStatusUpdate/LoadStatusUpdate';
import { loadHistoryStyle } from './LoadHistoryStyle';

function LoadHistory({ load }) {
    const classes = loadHistoryStyle()

    const types = {
        LOAD_STATUS: 1,
        ACIVITY_STATUS: 2
    }
    //const history = load?.statuses;
    const loadLookup = arrayToObject(load?.loads, 'id');

    let history = orderBy([
        ...(load?.statuses?.map(a => ({ ...a, type: types.LOAD_STATUS, date: a.statusDate, legId: loadLookup?.[a.loadId]?.order ? `Leg ${loadLookup?.[a.loadId]?.order} - ` : '' })) ?? []),
        ...(load?.activityHistory?.map(a => ({ ...a, type: types.ACIVITY_STATUS, date: a.activityDate })) ?? [])
    ], 'date', 'desc');

    const [statusToEdit, setStatusToEdit] = useState();
    let [deleteStatus, { isLoading: isLoadingDeleteStatus, isSuccess: deleteStatusSuccess }] = useDeleteStatusMutation();
    const { triggerPrompt } = usePrompt();

    const actions = [
        {
            key: 'edit',
            label: 'Edit',
            onClick: (status) => setStatusToEdit(status)
        },
        {
            key: 'delete',
            label: 'Delete',
            onClick: (status) => {
                triggerPrompt({
                    title: "Delete Status",
                    content: "Are you sure you want to delete the status?",
                    loading: isLoadingDeleteStatus,
                    onConfirm: async () => {
                        await deleteStatus({ id: status.id, groupId: load?.groupId });
                    },
                });
            }
        }
    ]
    return (
        <>
            {!(history?.length) ?
                <EmptyPlaceholder text='no history' className={classes.emptyPlaceHolder} /> :
                <div className={classes.listWrapper}>
                    <List>
                        {history?.map(h => (
                            h.type == types.LOAD_STATUS ? <StatusItem status={h} load={load} actions={actions} />
                                : <ActivityItem activity={h} />
                        ))}
                    </List>
                </div>
            }
            <LoadStatusUpdate
                loads={[load]}
                statusToEdit={statusToEdit}
                onClose={() => setStatusToEdit(null)}
                forEdit={true}
            />

        </>
    );
}

export default LoadHistory;


const ActivityItem = ({ activity }) => {
    const classes = loadHistoryStyle();

    let { data: activityItems, error: activityItemError, isLoading: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: activities, error: activityError, isLoading: isLoadingActivity } = useGetAllActivitiesQuery();
    return (
        <ListItem className={classes.historyItem}>
            <ListItemIcon><IconSettings /></ListItemIcon>
            <ListItemText
                primary={
                    <>
                        {activityItems?.find(a => a.id == activity.activityItemId)?.name} {activities?.find(a => a.id == activity.activityId)?.name}
                    </>}
                secondary={FormatService.formatDate(activity?.activityDate)} secondaryTypographyProps={{ style: { opacity: '0.6' } }}
            />
        </ListItem>
    )
}
const StatusItem = ({ status, actions, load }) => {
    const classes = loadHistoryStyle();
    const getStatus = (key) => {
        return { label: statuses[key.status]?.label || key.status, icon: statuses[key.status]?.icon };
    };

    const getDate = (date) => {
        const options = {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        };
        return date ? new Date(moment(date).format("yyyy-MM-DD, hh:mm a") + " UTC").toLocaleString({}, options) : "";
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorComment, setAnchorComment] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleCommentOpen = (event) => {
        setAnchorComment(event.currentTarget);
    };

    const handleCommentClose = () => {
        setAnchorComment(null);
    };


    return (
        <>
            <ListItem className={classes.historyItem}>
                <ListItemIcon>
                    {getStatus(status).icon}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <>
                            {status?.legId} <LoadStatus load={load} status={status} />
                        </>}
                    secondary={getDate(status.statusDate)} secondaryTypographyProps={{ style: { opacity: '0.6' } }}
                />
                {!!status.comment && <ListItemIcon>
                    <CommentIcon
                        className={classes.commentIcon}
                        onClick={handleCommentOpen}
                    />
                    <Popover
                        open={!!anchorComment}
                        anchorEl={anchorComment}
                        onClose={() => setAnchorComment(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className={classes.statusTootlip}>
                            {status.comment}
                        </div>
                    </Popover>
                </ListItemIcon>}
                <Authorize profile={permissionProfiles.LOAD.LOAD_ACTIONS}>
                    <ListItemIcon className={classes.historyItemActions} onClick={handleClick} >
                        <MoreVertIcon />
                    </ListItemIcon>
                </Authorize>
            </ListItem>
            <Menu
                id="lot-actions"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lot-menu',
                }}
            >
                {actions.map(action =>
                    <MenuItem key={action.key} onClick={() => {
                        action.onClick(status);
                        handleClose();
                    }} >
                        {action.label}
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}