import { IconButton, Tooltip } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { copyToClipboardStyle } from './style';
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard';
import { IconCopy } from 'icons';

const CopyToClipboard = ({ text, title = 'Copy to clipboard', children }) => {
    const classes = copyToClipboardStyle();

    return (
        <>
            {children}
            <Copy text={text}>
                <Tooltip title={title}>
                    <IconButton size='small'>{<IconCopy className={classes.icon} color='secondary' />} </IconButton>
                </Tooltip>
            </Copy>
        </>
    )
}
export default CopyToClipboard;