export const userPreferencesComponentIds = {
    LOT_MAP: 'LOT_MAP',
    VEHICLES_PAGE: 'VEHICLES_PAGE',
    VEHICLES_TABLE: 'VEHICLES_TABLE',
    EXPORT_LOADS: 'EXPORT_LOADS',
    LOADS_PAGE: 'LOADS_PAGE',
    ACTIVITY_LOG: 'ACTIVITY_LOG',
    ISSUE_TRACKER: 'ISSUE_TRACKER',
    ISSUE_TRACKER_KANBAN: 'ISSUE_TRACKER_KANBAN',
    ISSUE_TRACKER_KANBAN_ARCHIVED: 'ISSUE_TRACKER_KANBAN_ARCHIVED',
    ASSET_MAP: 'ASSET_MAP',
    USER_ACTIVE_MAP: 'USER_ACTIVE_MAP',
    PCO_INVOICE_TABLE: 'PCO_INVOICE_TABLE',
}

export const userPreferencesTypes = {
    FILTERS: 1,
    COLUMNS: 2,
    CUSTOM_VALUE: 3,
    LAST_VIEW_ID: 4
}