import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    formContainer: {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        //width: '600px',
        //padding: '1em'
    },
    mainTitle: {
        fontSize: '1.6em',
        fontWeight: 500,
        marginBottom: "1.5rem",
    },
    form: {
        display: 'flex',
        overflow: "auto",
        flexDirection: 'column',
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: theme.palette.common.white,
        margin: "16px 20px"
    },
    btnWrapper: {
        marginLeft: '0.5rem',
    },
    configWrapper: {
        borderRadius: '5px',
        margin: 16,
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100'
    },
}));
