import { Grid, Paper } from "@material-ui/core";
import AttachmentsView from "features/attachments/AttachmentsView";
import { AttachmentEntityTypes } from "features/attachments/attachmentsConsts";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { IconBox, IconText } from "../../icons";
import NoteList from "../notes/NoteList";
import { NoteEntityTypes } from "../notes/notesConsts";
import CardHeader from "./cardHeader";
import InventoryUsed from "./components/Inventory/InventoryUsed";
import StepsToComplete from "./components/StepsToComplete/StepsToComplete";
import { mechanicalWorkTaskStyle } from "./mechanicalWorkTaskStyle";
import MechanicalWorkDetails from "./components/MechanicalWorkDetails/MechanicalWorkDetails";
import { useGetAllActivityItemsQuery, useGetWorkOrderInfoQuery } from "features/activity/activitySlice";
import IconMechanicalWrapper from "icons/mechanicals/IconMechanicalWrapper";
import { FlagAsset } from "features/vehicles/Flags/FlagAsset";
import { FlagEntityTypes } from "features/flag/flagsConsts";
import PromptUnsavedChanges from "components/PromptUnsavedChanges/PromptUnsavedChanges";

const ActivityWorkOrder = () => {
    const params = useParams();

    const classes = mechanicalWorkTaskStyle();

    const [existingImages, setExistingImages] = useState();
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    let { data: activityResult, error: vehicleDetailsError, isLoading: isVehicleDetailsLoading, refetch: refecthLog } = useGetWorkOrderInfoQuery(params.id);
    let { data: activityItems, error: activityItemError, isLoading: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    let currentActivityItem = activityItems?.find(a => a.id === activityResult?.data?.activityItemId);

    useEffect(() => {
        if (!existingImages) {
            setExistingImages(activityResult?.data?.images);
        }

    }, [activityResult?.data])

    if (isVehicleDetailsLoading) {
        return <LoadingSpinner loading={isVehicleDetailsLoading} />
    }

    return (
        <>
            <PromptUnsavedChanges
                hasUnsavedChanges={hasUnsavedChanges}
            />

            <Paper className={`${classes.card}`} elevation={1}>
                <div className={classes.header} style={{ paddingLeft: 5 }}>
                    <Grid container spacing={1} alignItems="center" >
                        <Grid item>
                            <IconMechanicalWrapper activityItem={currentActivityItem} className={classes.typeIcon} />
                        </Grid>
                        <Grid item className={classes.activityName}>{`${currentActivityItem?.name} Work Order`}</Grid>
                        <Grid item justifyContent="flex-end">
                            <FlagAsset
                                selectedRows={[activityResult?.data?.id]}
                                allData={[{ ...activityResult?.data, vehicleId: activityResult?.data?.vehicle?.id }]}
                                entityType={FlagEntityTypes.ACTIVITY}
                            />
                        </Grid>
                    </Grid>
                </div>

                <MechanicalWorkDetails activity={activityResult?.data} currentActivityItem={currentActivityItem} />
            </Paper>

            {/*Steps to complete*/}
            <Paper className={`${classes.card}`} elevation={1}>

                <CardHeader icon={<IconText />} title='Steps to complete:' />

                <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}
                    className={classes.details}>
                    <StepsToComplete activity={activityResult?.data} setHasUnsavedChanges={setHasUnsavedChanges} />
                </Grid>

            </Paper>

            {/*Inventory used*/}
            <Paper className={`${classes.card}`} elevation={1}>
                <CardHeader title='Inventory used' icon={<IconBox />} />
                <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}
                    className={classes.details}>
                    <InventoryUsed activity={activityResult?.data} setHasUnsavedChanges={setHasUnsavedChanges} />
                </Grid>
            </Paper>

            {/*Notes*/}
            <Paper className={`${classes.card}`} elevation={1}>
                <NoteList entityId={activityResult?.data?.id} entityType={NoteEntityTypes.ACTIVITY} />
            </Paper>

            {/*Photos & Documents*/}
            <Paper className={`${classes.card}`} elevation={1}>
                <div className={classes.sideBySide}>
                    <AttachmentsView entityId={activityResult?.data?.id} entityType={AttachmentEntityTypes.ACTIVITY} showTitle={true} />
                </div>
            </Paper>
        </>
    )
}

export default ActivityWorkOrder;


