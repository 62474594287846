import { createSlice } from "@reduxjs/toolkit";
import { toArray } from "utils/object-util";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isLoggingOut: false,
    },
    reducers: {
        logOut: state => {
            state.isLoggingOut = true;
        }
    },
});


export const selectIsAdmin = state => state.oidc && state.oidc.user && state.oidc.user.profile && (state.oidc.user.profile.role === "admin" || state.oidc.user.profile.role === "owner" || state.oidc.user.profile.role === "account_manager" || state.oidc.user.profile.role === "mechanic");
export const selectIsAdminOnly = state => state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.role === "admin";
export const selectIsOwner = state => state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.role === "owner";
export const selectIsClient = state => state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.role === "client";
export const selectIsAccountManager = state => state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.role === "account_manager";
export const selectIsSupervisor = state => state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.role === "supervisor";
export const selectIsSeamless = state => !state?.oidc?.user && !!state.seamlessToken.token;
export const selectIsMechanic = state => state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.role === "mechanic";
export const selectUserEmail = state => state?.oidc?.user?.profile?.email || "";
export const selectUserRole = state => state?.oidc?.user?.profile?.role || "";
export const selectUserClientId = state => state?.oidc?.user?.profile?.clientId || "";
export const selectUserLotId = state => state?.oidc?.user?.profile?.lotId || "";
export const selectUserPermissions = state => toArray(state?.oidc?.user?.profile?.permission)
export const selectIsLoggingOut = state => state.user.isLoggingOut;
export const selectUserId = state => state?.oidc?.user?.profile?.sub;
export const selectRole = state => {
    if (state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile) {
        return state.oidc.user.profile.role;
    }
}

export const { logOut } = userSlice.actions;
export default userSlice.reducer;
