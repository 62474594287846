import { Box, Tab, Tabs } from "@material-ui/core";
import { useState, useEffect } from "react";
import { tabStyles } from "./style";
import Authorize from "../Authorize";
import { isAuthorized } from "../Authorize/authorizeUtil";

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

export const TabsWrapper = ({ tabs, selectedTab, setSelectedTab, className }) => {
    const classes = tabStyles();
    const filteredTabs = tabs?.filter(tab => !tab.permission || isAuthorized(tab.permission)) || [];
    const [value, setValue] = useState(selectedTab ?? 0);

    useEffect(() => {
        if (filteredTabs.length > 0 && value >= filteredTabs.length) {
            setValue(0); // Reset to first tab if current value is out of range
        }
    }, [filteredTabs.length]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSelectedTab && setSelectedTab(newValue);
    };

    return (
        <>
            <Box className={`${classes.tabsWrapper} ${className}`}>
                <Tabs value={value} onChange={handleChange} centered>
                    {filteredTabs.map((tab, index) => (
                        <Tab {...tabProps(index)} key={`v_tab_${index}`} label={tab.label} value={index} />
                    ))}
                </Tabs>
            </Box>
            <Box>
                {filteredTabs.map((tab, index) => (
                    <div key={index}>
                        {tab.permission ? (
                            tab.component &&
                            <Authorize profile={tab.permission}>
                                <TabPanel value={value} index={index}>
                                    {tab.component}
                                </TabPanel>
                            </Authorize>
                        ) : (
                            tab.component && (
                                <TabPanel value={value} index={index}>
                                    {tab.component}
                                </TabPanel>
                            )
                        )}
                    </div>
                ))}
            </Box>
        </>
    );
};

