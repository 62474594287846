import { makeStyles } from "@material-ui/core";

export const TimePickerStyle = makeStyles((theme) => ({
    picker: {
        width: 'fit-content',
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none'
        }
    }
}));