import { Button, Collapse, IconButton, Paper, Tooltip } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Authorize from "components/Authorize";
import CustomInput from "components/CustomInput";
import DatePicker from "components/DatePicker";
import DateRange from "components/DateRange";
import AssociatedLotsDropDown from "components/SharedDropdowns/LotsDropDown/AssociatedLotsDropDown";
import { convertFiltersFunc } from "components/UserPreferences/WithUserPreferences";
import { userPreferencesTypes } from "components/UserPreferences/userPreferencesConsts";
import { useGetUserPreferencesQuery, useUpdateUserPreferenceMutation } from "components/UserPreferences/userPreferencesSlice";
import { IconFilters } from "icons";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fieldTypes } from "services/filterService";
import { collapseFilters } from "../genericTableStateSlice";
import ListFilter, {ActivityTypeListFilter, AssetStatusFilter, LotsFilter, VehicleTypeListFilter} from "./ListFilters";
import { tableFiltersStyle } from "./style";

const TableFilters = ({
    tableId,
    selectedViewId,
    dropDownsConfig,
    filters,
    onDropFilter,
    onResetFilters,
    updateAllFilters,
    useApplyButton,
    filtersSetFromOtherScreen = false,
    setFiltersSetFromOtherScreen,
    customFilterBehavior = false
}) => {

    const classes = tableFiltersStyle();
    const dispatch = useDispatch();

    const { collapsedFilters } = useSelector(state => state.genericTableState);

    const [filtersState, setFiltersState] = useState(filters);

    let { data: userFiltersPreferences, error: filtersError, isLoading: isLoadingFilters } = useGetUserPreferencesQuery({ componentId: tableId, type: userPreferencesTypes.FILTERS, viewId: selectedViewId || 0 }, { skip: !tableId });
    const [updatePref, { isLoading: isSaving }] = useUpdateUserPreferenceMutation();

    useEffect(() => {
        setFiltersState(filters);
    }, [filters]);

    useEffect(() => {
        if (!filtersSetFromOtherScreen) {
            if (userFiltersPreferences?.filters) {
                convertFilters(userFiltersPreferences)
            } else {
                onResetFilters();
            }
        }
    }, [userFiltersPreferences]);

    const convertFilters = (prefFilters) => {
        let filters = convertFiltersFunc(prefFilters);
        updateAllFilters && updateAllFilters(filters)
    }

    const onFilterChange = (value, filterKey) => {
        const newFilters = { ...filtersState, [filterKey]: { value } }
        setFiltersState(newFilters);
        setFiltersSetFromOtherScreen && setFiltersSetFromOtherScreen(false);
        if (onDropFilter && !useApplyButton) {
            onDropFilter(value, filterKey);
            saveViewFilters(newFilters);
        }
    }

    const onApply = () => {
        updateAllFilters(filtersState);
        saveViewFilters(filtersState);
    }

    const saveViewFilters = debounce((listToSave) => {
        if (!tableId) return;
        let filtersList = Object.entries(listToSave).map(([key, value]) => ({ key: key, value: JSON.stringify(value) }));
        const prefToSave = { filters: filtersList };
        let res = updatePref({ componentId: tableId, type: userPreferencesTypes.FILTERS, ...prefToSave, viewId: selectedViewId })
    }, 500)

    const onClearAll = () => {
        onResetFilters();
        saveViewFilters({});
    }

    return (
        <>
            {collapsedFilters ?
                <>
                    {/* <ExpandFiltersPanel filtersState={filtersState} /> */}
                </> : <Paper className={classes.filtersContainer}>
                    <div className={classes.header}>
                        <div style={{ display: 'flex' }}>
                            <span className={classes.filterIconWhite} >
                                <IconFilters />
                            </span>
                            <span className={classes.title}>Filters</span>
                        </div>
                        <div>
                            <Button color="primary" variant="outlined" className={classes.clearBtn} onClick={onClearAll}>Clear All</Button>
                            <Tooltip title={'Collapse filters panel'}>
                                <IconButton className={classes.collapseIcon} size="small" onClick={() => dispatch(collapseFilters(true))}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={classes.filtersList}>
                        {dropDownsConfig?.map((ddConfig, index) => (
                            <Filter ddConfig={ddConfig} onFilterChange={onFilterChange} filtersState={filtersState} customFilterBehavior={customFilterBehavior}/>
                        ))
                        }
                    </div>
                    {useApplyButton &&
                        <Button color="primary" variant="outlined"
                            className={classes.useApplyButton}
                            onClick={onApply}>
                            Apply
                        </Button>
                    }
                </Paper>}
        </>
    )
}
export default TableFilters;


const Filter = ({ ddConfig, onFilterChange, filtersState , customFilterBehavior = false}) => {
    const classes = tableFiltersStyle();
    const allMultipleOptions = customFilterBehavior == true ? false : true;

    const [collapsed, setCollapsed] = useState(false);

    return (
        <>
            {ddConfig &&
                <Authorize profile={ddConfig.profile}>
                    <div className={classes.itemWrapper}>
                        {ddConfig.title &&
                            <div className={classes.itemHeader}>
                                <div className={classes.itemTitle} onClick={() => setCollapsed(!collapsed)}>{ddConfig.title}</div>
                                <div className={classes.clearBtnContainer}>
                                    {(filtersState[ddConfig.key]?.value) &&
                                        <Button color="primary" className={classes.clearBtn} onClick={() => { onFilterChange(null, ddConfig.key) }}>
                                            Clear
                                            {ddConfig.multiple && ` (${filtersState[ddConfig.key]?.value?.length})`}
                                        </Button>
                                    }
                                    <KeyboardArrowDownIcon onClick={() => setCollapsed(!collapsed)} />
                                </div>
                            </div>
                        }
                        <Collapse in={collapsed} timeout="auto" unmountOnExit className={classes.collapseWrapper}>
                            {
                                (ddConfig.type === fieldTypes.TEXT || ddConfig.type === fieldTypes.LIST || ddConfig.type === fieldTypes.VALUE_LIST || ddConfig.type === fieldTypes.VALUE_LIST_OBJECT) &&
                                <ListFilter
                                    values={ddConfig.getValues ? ddConfig.getValues() : ddConfig.values}
                                    value={filtersState[ddConfig.key]?.value ?? []}
                                    onChangeValue={onFilterChange}
                                    multiple={(allMultipleOptions && ((ddConfig.getValues ? ddConfig.getValues()?.length : ddConfig.values?.length) > 2)) || (ddConfig.type === fieldTypes.LIST || ddConfig.type === fieldTypes.VALUE_LIST)}
                                    identifier={ddConfig.key}
                                />
                            }
                            {
                                ddConfig.type === fieldTypes.DATE &&
                                <DatePicker
                                    title={ddConfig.title}
                                    identifier={ddConfig.key}
                                    value={filtersState[ddConfig.key]?.value}
                                    onSelect={onFilterChange} />
                            }
                            {
                                ddConfig.type === fieldTypes.DATERANGE &&
                                <DateRange
                                    // title={ddConfig.title}
                                    identifier={ddConfig.key}
                                    onSelect={onFilterChange}
                                    dateValue={filtersState[ddConfig.key]?.value}
                                    filterButton
                                />
                            }
                            {
                                ddConfig.type === fieldTypes.FREETEXT &&
                                <CustomInput
                                    label={ddConfig.title}
                                    value={filtersState[ddConfig.key].value}
                                    id={ddConfig.key}
                                    onChange={onFilterChange} />
                            }
                            {
                                ddConfig.type === fieldTypes.LOT &&
                                <LotsFilter
                                    clientId={filtersState['clientId']?.value}
                                    identifier={ddConfig.key}
                                    onSelect={onFilterChange}
                                    value={filtersState[ddConfig.key]?.value}
                                    pmfLot={ddConfig.pmfLot}
                                    multiple={allMultipleOptions || ddConfig.multiple}
                                />
                            }
                            {
                                ddConfig.type === fieldTypes.ASSOCIATED_LOT &&
                                <AssociatedLotsDropDown
                                    title={ddConfig.title}
                                    identifier={ddConfig.key}
                                    values={ddConfig.values}
                                    onSelect={onFilterChange}
                                    value={filtersState[ddConfig.key]?.value}
                                    showEmpty={true} />
                            }
                            {
                                ddConfig.type === fieldTypes.ASSET_STATUS &&
                                <AssetStatusFilter
                                    identifier={ddConfig.key}
                                    clientId={filtersState['clientId']?.value}
                                    values={ddConfig.values}
                                    onSelect={onFilterChange}
                                    value={filtersState[ddConfig.key]?.value}
                                    multiple={allMultipleOptions || ddConfig.multiple}
                                />
                            }            
                            {
                                ddConfig.type === fieldTypes.VEHICLE_TYPE &&
                                <VehicleTypeListFilter
                                    identifier={ddConfig.key}
                                    clientId={filtersState['vechicleTypeId']?.value}
                                    values={ddConfig.values}
                                    onSelect={onFilterChange}
                                    value={filtersState[ddConfig.key]?.value}
                                    multiple={allMultipleOptions || ddConfig.multiple}
                                />
                            }           
                            {
                                ddConfig.type === fieldTypes.ACTIVITY_TYPE &&
                                <ActivityTypeListFilter
                                    identifier={ddConfig.key}
                                    clientId={filtersState['activityTypeId']?.value}
                                    values={ddConfig.values}
                                    onSelect={onFilterChange}
                                    value={filtersState[ddConfig.key]?.value}
                                    multiple={allMultipleOptions || ddConfig.multiple}
                                />
                            }
                            {
                                ddConfig.type === fieldTypes.COMPONENT &&
                                <div>
                                    {ddConfig.component}
                                </div>
                            }

                        </Collapse>
                    </div>
                </Authorize>
            }
        </>
    );
};

