import { MenuItem, Select } from "@material-ui/core";
import { FormBuilderStyles } from "../../style";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const ListFieldMapping = ({ value, values, onChangeVal }) => {
    const classes = FormBuilderStyles();

    const renderValue = (selected) => {
        let name = values?.find(s => s.id == selected)?.name;
        return <div className={`${classes.selectItem}`}>
            {name ?? <em>Select a value to Map...</em>}
        </div>
    };

    return (
        <>
            <Select
                native={false}
                className={classes.select}
                value={value}
                required
                onChange={(e) => {
                    onChangeVal(e.target.value)
                }}
                variant="outlined"
                inputProps={{
                    className: classes.select,
                }}
                MenuProps={{
                    className: classes.selectMenu,
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    transformOrigin: { vertical: "top", horizontal: "left" },
                    getContentAnchorEl: null
                }}
                IconComponent={ExpandMoreIcon}
                renderValue={renderValue}
                defaultValue={0}
            >
                {values?.map((value, key) => (
                    <MenuItem className={classes.statusItem}
                        id={key} key={key} value={value.id}>
                        {value.name}
                    </MenuItem>
                ))}
            </Select>
        </>
    )
}