import { IconImage, IconQuestion, IconSubtitle, IconTitle } from "icons";
import { formTypes, questionTypes } from "../formBuilder/formBuilderConsts";
import { ElementComponent } from "./ReportSections/ReportSection/ReportColumn/ElementComponent";


export const reportElementTypes = {
    Question: 1,
    Title: 2,
    Subtitle: 3,
    Image: 4
}

export const elementTypeSetup = {
    [reportElementTypes.Question]: {
        id: reportElementTypes.Question,
        name: 'Add Single Question',
        icon: <IconQuestion style={{ width: '2em', height: '2em' }} />,
        component: (element, onEditElement) => <ElementComponent
            elementType={reportElementTypes.Question}
            element={element}
            onEditElement={onEditElement} />
    },
    [reportElementTypes.Title]: {
        id: reportElementTypes.Title,
        name: 'Add Title',
        icon: <IconTitle style={{ width: '2em', height: '2em' }} />,
        component: (element, onEditElement) => <ElementComponent
            elementType={reportElementTypes.Title}
            element={element}
            onEditElement={onEditElement} />
    },
    [reportElementTypes.Subtitle]: {
        id: reportElementTypes.Subtitle,
        name: 'Add Subtitle',
        icon: <IconSubtitle style={{ width: '2em', height: '2em' }} />,
        component: (element, onEditElement) => <ElementComponent
            elementType={reportElementTypes.Subtitle}
            element={element}
            onEditElement={onEditElement} />
    },
    [reportElementTypes.Image]: {
        id: reportElementTypes.Image,
        name: 'Add Image',
        icon: <IconImage style={{ width: '2em', height: '2em' }} />,
        component: (element, onEditElement) => <ElementComponent
            elementType={reportElementTypes.Image}
            element={element}
            onEditElement={onEditElement} />
    },
}

export const emptyReport = (formType) => {
    return {
        formType,
        title: '',
        description: '',
        sections: []
    }
}


export const mockData = {
    title: 'My custom report',
    description: 'Description of report...',
    formType: formTypes.Checkin,
    sections: [
        {
            columns: [
                {
                    width: 3,
                    elements: [
                        {
                            elementType: reportElementTypes.Question,
                            value: 449,
                        }
                    ]
                }
            ]
        },
        {
            columns: [
                {
                    width: 1,
                    elements: [
                        {
                            elementType: reportElementTypes.Title,
                            value: 'Sara'
                        }
                    ]
                },
                {
                    width: 1,
                    elements: [
                        {
                            elementType: reportElementTypes.Subtitle,
                            value: 'Sara'
                        },
                        {
                            elementType: reportElementTypes.Image,
                            value: 'http://photos2.liquidmotors.com/kd16003/kl40941154/szLG/po1/pic.aspx'
                        },
                    ]
                }
            ]
        }
    ]
};
