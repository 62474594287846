import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    Switch,
    Tooltip
} from "@material-ui/core";
import { Alert } from "@mui/material";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import NotificationProvider from "components/NotificationProvider";
import { useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";
import { FlagEntityTypes } from "features/flag/flagsConsts";
import { useFlagAssetsMutation } from "features/flag/flagsSlice";
import { NoteContent } from "features/notes/NoteEditor";
import { selectIsAccountManager, selectIsClient, selectIsOwner } from "features/user/userSlice";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { arrayToObject } from "utils/object-util";
import CustomInput from "../../../components/CustomInput";
import DatePicker from "../../../components/DatePicker";
import DropdownFilter from "../../../components/DropdownFilter";
import Validator from "../../../services/validator";
import { bulkActionsStyle } from "./style";
import { NoteEntityTypes } from "features/notes/notesConsts";

export const FlagAssetDialog = ({
    selectedRows,//injected by the bulkActions
    allData,
    addFlag,
    flagOn,
    entityType,
    sourceType,
    open,
    setOpen
}) => {


    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);
    let [flagAssets, { isLoading, isError, isSuccess }] = useFlagAssetsMutation();

    sourceType = sourceType;

    const classes = bulkActionsStyle();
    const [noteToEdit, setNoteToEdit] = useState({});
    const [followUp, setFollowUp] = useState(false);
    const [needCustomDate, setNeedCustomDate] = useState(false);
    const [emails, setEmails] = useState(flagAssets?.emails ?? "");
    const [date, setDate] = useState(Date.now());
    const [needSetDate, setNeedSetDate] = useState(false);
    const [option, setOption] = useState(3);
    const [fieldsCompleted, setFieldsCompleted] = useState(false);

    const optionItems = [
        { id: 1, name: "3 days" }
        , { id: 2, name: "1 Week" }
        , { id: 3, name: "Send Immediately" }
        , { id: 4, name: "Select a Date" }
    ];

    let isOwner = useSelector(selectIsOwner);
    let isAccountManager = useSelector(selectIsAccountManager);
    let isClient = useSelector(selectIsClient);

    const dataLookup = arrayToObject(allData);
    const entities = selectedRows?.map(r => dataLookup?.[r]);
    const clientAccountIds = entities?.map(a => a?.customerId ?? 0) ?? [];

    const clientConfigs = clientsWithConfigs?.filter(c => clientAccountIds.includes(c.accountId));
    const configLookup = arrayToObject(clientConfigs, 'accountId');
    const canSeeFlagsConfig = clientConfigs?.map(c => c.config?.lotConfig?.seeFlags);
    const someClientsCanSeeFlags = canSeeFlagsConfig?.some(c => !!c);
    const allClientCanSeeFlags = canSeeFlagsConfig?.every(c => !!c);
    const allClientCantSeeFlags = canSeeFlagsConfig?.every(c => !c);
    const multiClient = clientConfigs?.length > 1;
    const canSeeFlags = isOwner || isAccountManager || (isClient && allClientCanSeeFlags)

    const isFlagOn = flagOn;
    const isAddFlag = addFlag || !isFlagOn;

    useEffect(() => {
        setNoteToEdit({ viewByClient: null, showInReport: null });
    }, [selectedRows])

    const onSave = async () => {
        setOpen(false);
        let result = await flagAssets(selectedRows?.map(a => ({
            ...dataLookup?.[a] ?? {},
            note: noteToEdit?.content ?? "",
            noteViewByClient: configLookup[dataLookup?.[a]?.customerId]?.config?.lotConfig?.seeFlags ? noteToEdit?.viewByClient == null ? true : false : false,
            addFlag: isAddFlag, //in bulk we add flags, when addFlag is false - if flagOn is false then we add a flag otherwize we remove the flag
            entityType: entityType,
            vehicleId: dataLookup?.[a]?.vehicleId,
            followUp: followUp,
            reminderDate: moment(date).toISOString(),
            emails: emails,
            selectedOption: option,
            loadGroupId: entityType == FlagEntityTypes.TRANSPORT ? dataLookup?.[a]?.groupId : null,
            showInReport: noteToEdit.showInReport
        })));
        if (result && !result.error) {
            NotificationProvider.success("Successfully updated asset flags");
            setNoteToEdit({})
        } else {
            NotificationProvider.error("Failed to add a flag to the assets");
        }
    }

    const onClose = () => {
        setOpen(false);
    }

    const onChange = (field, value) => {
        setNoteToEdit({ ...noteToEdit, [field]: value });
    }

    const onChangeOption = (value) => {
        let currentDate = Date.now();
        setOption(value);
        setNeedSetDate(false);
        setNeedCustomDate(false);
        let finalDate = moment(currentDate).format("YYYY-MM-DD");
        switch (value) {
            case "1": // {id: 1, name:"3 days"}
                let threeDaysLater = currentDate + (3 * 24 * 60 * 60 * 1000);
                finalDate = moment(threeDaysLater).format("YYYY-MM-DD");
                break;
            case "2":// ,{id: 2, name:"1 Week"}
                let oneWeekLater = currentDate + (7 * 24 * 60 * 60 * 1000);
                finalDate = moment(oneWeekLater).format("YYYY-MM-DD");
                break;
            case "3":// ,{id: 3, name:"Send Immediately"}
                setDate(currentDate);
                break
            case "4":// ,{id: 4, name:"Select a Date"}
                setNeedSetDate(true);
                setNeedCustomDate(true);
                break
        }
        setDate(finalDate);
    }

    const checkFieldsCompletion = () => {
        let areEmailsCompleted = emails !== "";
        const emailArray = emails.split(';').map(e => e.trim());
        if (areEmailsCompleted) {
            for (let i = 0; i < emailArray.length; i++) {
                const email = emailArray[i];
                const res = Validator.validateEmail(String(email).toLowerCase());
                if (!res) {
                    areEmailsCompleted = false;
                    break;
                }
            }
        }
        const isNoteCompleted = noteToEdit?.content !== "" && noteToEdit?.content !== undefined;
        let isDateCompleted = true;
        if (followUp) {
            isDateCompleted = !needSetDate || (needSetDate && date !== "");
        } else {
            areEmailsCompleted = true;
        }

        setFieldsCompleted(areEmailsCompleted && isDateCompleted && isNoteCompleted);
    };


    useEffect(() => {
        checkFieldsCompletion();
    }, [followUp, emails, needSetDate, date, noteToEdit]);

    const handleFollowUpChange = (event) => {
        setFollowUp(event.target.checked);
        if (!event.target.checked) {
            setFieldsCompleted(true);
        } else {
            checkFieldsCompletion()
        }
    };

    const handleEmailsChange = (value) => {
        setEmails(value);
        checkFieldsCompletion();
    };
    const handleDateChange = (newDate) => {
        setDate(newDate);
        checkFieldsCompletion();
    };

    return (

        <Authorize profile={permissionProfiles.ASSETS.FLAG_ASSETS}>
            <Dialog onClose={onClose} open={open} maxWidth='md' fullWidth>
                <DialogTitle>
                    {isAddFlag ? "Add Flag" : "Remove flag"}
                </DialogTitle>
                <DialogContent>
                    {someClientsCanSeeFlags && <p><Alert severity='warning' >
                        {multiClient ? 'Some of the clients of the assets you are updating will be able to see this flag' : 'The client will be able to see this flag'}
                    </Alert></p>}
                    {isAddFlag && (
                        <>
                            <label>Follow Up</label>
                            <Switch
                                color="primary"
                                checked={followUp}
                                onChange={handleFollowUpChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </>

                    )}

                    {
                        followUp && (
                            <Grid container className={classes.gridContainer}>
                                <Grid item xs={5} className={classes.lineItem}>
                                    <DropdownFilter
                                        title='Options'
                                        identifier='id'
                                        values={optionItems}
                                        value={option}
                                        required={followUp}
                                        onSelect={(val) => onChangeOption(val)}
                                    />
                                </Grid>
                                <Grid item xs={5} className={classes.lineItem}>
                                    <CustomInput
                                        label="To"
                                        identifier="emails"
                                        value={emails}
                                        required={followUp}
                                        onChange={handleEmailsChange}
                                    />
                                </Grid>
                                {
                                    needSetDate && (
                                        <Grid item xs={5} className={classes.lineItem}>
                                            <DatePicker
                                                label="Set Date"
                                                identifier="Date"
                                                value={date}
                                                onSelect={handleDateChange}
                                                required={needCustomDate}
                                                minDate={moment(Date.now()).format("YYYY-MM-DD")}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        )
                    }
                    <NoteContent
                        content={noteToEdit.content}
                        viewByClient={noteToEdit?.viewByClient == null && someClientsCanSeeFlags ? true : noteToEdit?.viewByClient}
                        onChange={onChange}
                        hideViewByClient={allClientCantSeeFlags}
                        entityType={entityType}
                        showInReport={noteToEdit?.showInReport}
                    />
                    {multiClient && someClientsCanSeeFlags &&
                        <Alert severity='warning' >
                            Some of these clients will not be able to see the note even if this box is selected.
                        </Alert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" variant="outlined" >Cancel</Button>
                    <Tooltip title={noteToEdit?.content ? '' : 'Please enter a note'}>
                        <span>
                            <Button onClick={onSave}
                                color="primary" variant="contained"
                                disabled={!fieldsCompleted} >Save</Button>
                        </span>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </Authorize>
    )

}