import { reportElementTypes } from 'features/Forms/CustomReport/CustomReportConsts';
import { Checkbox, FormControlLabel, Grid, Paper, TextField } from '@material-ui/core';
import { ElementsStyles } from './ElementsStyles';
import { useRef } from 'react';
import { IconImage } from 'icons';
import { useGetFormByTypeForClientQuery } from 'features/Forms/formBuilder/formBuilderSlice';
import { useSelector } from 'react-redux';
import CustomInput from 'components/CustomInput';
import FileService from 'services/fileService';

export const ElementComponent = ({ elementType, element, onEditElement }) => {
    const classes = ElementsStyles();
    const inputFile = useRef(null);

    const { clientId, formType } = useSelector(state => state.formState);

    let { data: formFromApi, error: formError, isFetching: isLoadingForms } = useGetFormByTypeForClientQuery({ clientId, formType, version: null }, { skip: !formType });

    const questionsList = formFromApi?.sections?.map(s => s.questions)?.flat()?.map(q => ({ ...q, name: q.question })) || [];

    const selectPhoto = () => {
        inputFile.current.click();
    };

    const onSelectFile = async e => {
        if (!e.target.files || e.target.files.length === 0) {
            // setSelectedFiles(undefined)
            return
        }

        let files = [...e.target.files];
        files.forEach(async file => {
            let url = URL.createObjectURL(file);
            let base64Data = await FileService.getBase64Data(file);
            onEditElement({ value: url, imageObj: { fileName: file.name, data: base64Data } });
        });
    };



    return (
        <>
            {elementType === reportElementTypes.Title &&
                <TextField
                    inputProps={{ style: { fontSize: 20, fontWeight: 500 } }}
                    style={{ width: '100%' }}
                    value={element?.value}
                    label=""
                    variant="standard"
                    onChange={e => onEditElement({ value: e.target.value })}
                    touched
                    placeholder="Add Title..."
                />
            }
            {elementType === reportElementTypes.Subtitle &&
                <TextField
                    inputProps={{ style: { fontSize: 15 } }}
                    style={{ width: '100%' }}
                    value={element?.value}
                    label=""
                    variant="standard"
                    onChange={e => onEditElement({ value: e.target.value })}
                    touched
                    placeholder="Add Subtitle..."
                />
            }
            {elementType === reportElementTypes.Image &&
                <>
                    {element.value ? <img
                        id="theImage"
                        height={100}
                        src={element?.value}
                        className={classes.image} /> :
                        <Grid item xs={3}>
                            <Paper className={classes.addPhotoContainer} onClick={selectPhoto}>
                                <IconImage />
                                Add Photo
                            </Paper>
                            <input
                                type='file'
                                ref={inputFile}
                                style={{ display: 'none' }}
                                accept={'image/*'}
                                onChange={(e) => {
                                    onSelectFile(e)
                                }} />
                        </Grid>
                    }
                </>
            }
            {elementType === reportElementTypes.Question &&
                <>
                    <CustomInput
                        elementType='dropdown'
                        value={element?.value}
                        values={questionsList}
                        onChange={val => onEditElement({ value: val, showQuestion: true })}
                        showEmpty
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={element?.showQuestion}
                            defaultChecked={true}
                            onChange={(e, value) => onEditElement({ showQuestion: value })}
                        />}
                        label="Show question text in report" />
                </>
            }
        </>
    )
}