import { Grid, Paper } from "@material-ui/core";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { orderBy } from "lodash";
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { FormBuilderStyles } from "../style";
import FormQuestion from "./FormQuestion";
import { QuestionActions } from "./QuestionActions";

const FormQuestions = ({
    form,
    questions,
    onFormFieldChange,
    onQuestionsChange,
    onDeleteQuestion,
    onDuplicateQuestion
}) => {

    const classes = FormBuilderStyles();

    const elementTypes = {
        question: 1,
        section: 2
    }

    const questiosSorted = orderBy(questions ?? form.questions ?? [], 'order');

    const onQuestionChange = (index, question) => {
        questiosSorted[index] = question;
        onQuestionsChange(questiosSorted)
    }


    const onSortEnd = ({ oldIndex, newIndex }) => {
        onQuestionsChange(arrayMove(questiosSorted, oldIndex, newIndex).map((q, index) => ({ ...q, order: index })))
    };

    return (
        <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {questiosSorted.map((q, index) => (
                <SortableItem
                    index={index}
                    question={q}
                    questionIndex={index}
                    onQuestionChange={onQuestionChange}
                    form={form}
                    onDeleteQuestion={onDeleteQuestion}
                    onDuplicateQuestion={onDuplicateQuestion}
                />
            ))}
        </SortableContainer>
    );
}
export default FormQuestions;



const SortableItem = sortableElement(({ question, questionIndex, onQuestionChange, form, onDeleteQuestion, onDuplicateQuestion }) => {
    const classes = FormBuilderStyles();
    const DragHandle = sortableHandle(() => <div className={classes.dragHandler}>
        <DragIndicatorIcon className={classes.dragIconQuestion} color="secondary" />
    </div>);
    return <Grid item>
        <Paper >
            <DragHandle />
            <div className={classes.questionContainer}>
                <FormQuestion question={question} form={form} onChange={(question) => onQuestionChange(questionIndex, question)} />
            </div>
            <QuestionActions
                onDeleteQuestion={onDeleteQuestion}
                onDuplicateQuestion={onDuplicateQuestion}
                questionIndex={questionIndex}
                question={question}
                onQuestionChange={onQuestionChange}
            />
        </Paper>
    </Grid>
});

const SortableContainer = sortableContainer(({ children }) => {
    const classes = FormBuilderStyles();
    return <Grid container direction="column" spacing={1} className={classes.questionsContainer}>
        {children}
    </Grid>;
});

