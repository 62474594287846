import { baseApi } from 'app/baseApi';
import { baseQuery } from "app/baseQuery";
import { apiTags } from 'app/store/apiTags';
import FormatService from '../../../services/formatService';

const baseUrl = 'doorRelease';
export const doorReleaseApi = baseApi.injectEndpoints({
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getDoorReleases: builder.query({
            query: (params) => {
                const queryString = Object.keys(params).map(param => params[param] ? `${param}=${params[param]}` : undefined).filter(k => k).join('&');
                return `${baseUrl}?${queryString}`
            },
            providesTags: [apiTags.DOOR_RELEASES],
            transformResponse: (response) => {
                return response?.map(doorRelease => ({
                    ...doorRelease,
                    formatedlotDate: FormatService.formatDateAndTime(doorRelease?.lotCreatedDate),
                    formatedDate: FormatService.formatToDatePicker(doorRelease?.lotCreatedDate ?? doorRelease?.createdDate),
                    formatedCreatedDate: FormatService.formatDateAndTime(doorRelease?.createdDate),
                })) ?? []
            }
        }),
        openLotGate: builder.mutation({
            query: body => ({
                url: `${baseUrl}/open_gate`,
                method: "POST",
                body
            }),
        }),
        saveDoorReleaseNote: builder.mutation({
            query: body => ({
                url: `${baseUrl}/save_note`,
                method: "POST",
                body
            }),
        }),
    }),
});

export const {
    useGetDoorReleasesQuery,
    useOpenLotGateMutation,
    useSaveDoorReleaseNoteMutation
} = doorReleaseApi;
