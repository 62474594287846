import { Link } from "@material-ui/core";
import NotificationProvider from "components/NotificationProvider";
import { FlagEntityTypes } from "features/flag/flagsConsts";
import statuses from "features/loads/loadStatusesWithIcons";
import { useGetLoadsQuery } from "features/loads/loadsSlice";
import useSortOptions from "hooks/useSortOptions";
import _, { first } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { arrayToObject } from "utils/object-util";
import { isAuthorized } from "../../components/Authorize/authorizeUtil";
import { permissionProfiles } from "../../components/Authorize/permissionProfiles";
import LoadButton from "../../components/LoadButton/LoadButton";
import FormatService from "../../services/formatService";
import { useGetClientsWithConfigsQuery } from '../clients/clientConfigSlice';
import { useGetClientsQuery } from "../clients/clientSlice";
import { useGetSubMarketsQuery } from "../lots/components/LotSubMarketsPage/lotSubMarketsSlice";
import { useGetLotsQuery } from "../lots/lotSlice";
import {
    selectIsAccountManager,
    selectIsAdmin,
    selectIsClient,
    selectIsOwner,
    selectUserClientId
} from "../user/userSlice";
import { FlagAsset } from "./Flags/FlagAsset";
import { useAddNewReportMutation } from "./Reports/reportSlice";
import { excludedTypes } from "./VehicleDetailExtended/VehicleDetailExtendedTypes";
import VehicleStatusView from "./VehicleStatus/VehicleStatusView";
import { useGetAllStatusesQuery, useGetStatusesForClientQuery } from "./VehicleStatus/vehicleStatusSlice";
import VehicleTable from "./VehicleTable";
import { readinessStatuses, readinessTypes } from "./components/AssetReadiness/readinessUtil";
import AssetReadinessStatuses from "./components/AssetReadinessStatuses/AssetReadinessStatuses";
import { useGetReadinessQuery } from './readiness/readinessSlice.js';
import { getLPTypeName } from './vehicleConsts';
import { setFilters, setSearchVal } from "./vehicleFiltersSlice";
import { vehiclePageStyles } from "./vehiclePageStyle";
import { useGetVehicleTypesQuery, useGetVehiclesQuery, useLazySearchVehiclesQuery } from "./vehicleSlice";
import Authorize from "components/Authorize";

const VehiclesPage = ({ }) => {
    const classes = vehiclePageStyles();
    const history = useHistory();

    let isAdmin = useSelector(selectIsAdmin);
    let isOwner = useSelector(selectIsOwner);
    let isAccountManager = useSelector(selectIsAccountManager);
    let isClient = useSelector(selectIsClient);
    let userClientId = useSelector(selectUserClientId);
    const [includeHistory, setIncludeHistory] = useState(false);
    let user = useSelector(state => state.oidc.user);
    let isUserLoaded = !!user;
    const { searchVal, filters } = useSelector(state => state.vehicleFilters);
    const [sortOptions, sortData] = useSortOptions();
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);

    let {
        data: vehiclesData,
        error: vehicleError,
        isFetching: isFetchingVehicles,
        refetch: refetchVehicles
    } = useGetVehiclesQuery({ includeHistory });
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let {
        data: clients,
        error: clientError,
        isLoading: isLoadingClients
    } = useGetClientsQuery(null, { skip: !isAdmin && !isAccountManager });
    let {
        data: vehicleStatuses,
        error: statusError,
        isLoading: isLoadingStatuses
    } = useGetStatusesForClientQuery({ clientId: 0 });
    let {
        data: clientsWithConfigs,
        error: clientsWithConfigsError,
        isFetching: isLoadingClientsWithConfigs
    } = useGetClientsWithConfigsQuery(null, { skip: !isUserLoaded });
    let { data: subMarkets, error: smError, isLoading: isLoadingSm } = useGetSubMarketsQuery();
    let {
        data: vehiclesReadiness,
        error: vehiclesReadinessError,
        isLoading: isLoadingVehiclesReadiness
    } = useGetReadinessQuery({}, { skip: isFetchingVehicles });
    let {
        data: loads,
        error: loadListError,
        isLoading: isLoadingLoads
    } = useGetLoadsQuery({}, { skip: isFetchingVehicles });
    let {
        data: vehicleClientsStatuses,
        error: vehicleStatusError,
        isLoading: isLoadingVehicleStatuses
    } = useGetAllStatusesQuery();
    const [addNewReport] = useAddNewReportMutation();

    let [searchVehicleTrigger] = useLazySearchVehiclesQuery()

    vehiclesData = vehiclesData || []; //vehicleSearchData?.vehicles
    //vehicleSearchData = vehicleSearchData || [];
    vehicleTypes = vehicleTypes || [];
    lots = lots || [];
    clients = clients || [];
    vehicleStatuses = vehicleStatuses || [];
    vehicleClientsStatuses = vehicleClientsStatuses || []
    clientsWithConfigs = clientsWithConfigs || [];
    loads = loads || [];

    const clientConfigs = clientsWithConfigs?.find(c => c.id == userClientId);
    const isUsingTransports = clientConfigs?.config?.transportConfig?.useTransport;
    const isUsingWash = clientConfigs?.config?.washConfig?.useWashProgram;
    const isUsingManualReadiness = clientConfigs?.config?.readinessConfig?.readinessType == readinessTypes.MANUAL;
    const canSeeFlags = isAuthorized(permissionProfiles.ASSETS.ASSETS_PAGE_CAN_SEE_FLAGS);

    const usingReadiness = isUsingTransports || isUsingWash || isUsingManualReadiness;

    const readinessByVehicleId = vehiclesReadiness?.reduce(function (map, obj) {
        map[obj.vehicleId] = obj;
        return map;
    }, {})

    const subMarketsForLot = {}
    subMarkets?.map(s => {
        s?.lotIds?.map(l => {
            subMarketsForLot[l] = [...(subMarketsForLot[l] ?? []), s.id + ""];
        })
    })

    const lotLookup = arrayToObject(lots);

    const isLoading = () => isFetchingVehicles || isLoadingLots || isLoadingVehicleTypes || isLoadingClients || isLoadingStatuses; // || isFetchingVehicleSearch

    const getLotName = id => {
        let lot = lotLookup?.[id] || {};
        return lot.name;
    };

    const getClientName = customerId => {
        let client = clients.find(c => c.clientAccounts.find(cu => cu.id === customerId)) || {};
        return client.name;
    };

    const getStatusName = (id, clientId) => {
        let status = vehicleClientsStatuses.find(s => s.id === id && s.aliasClientId == clientId);
        if (!status) status = vehicleClientsStatuses.find(s => s.id === id);
        return status.name;
    }

    let vehicleColumns = [
        {
            name: "VIN", key: "descriptor", selectedByDefault: true, width: 280,
            component: (row) => {
                return <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <Link style={{ color: "#46535d", paddingLeft: 3 }} href={`/assets/detail/${row?.id}`}
                        target="_blank">{row?.descriptor}</Link>
                </div>
            }
        },
        {
            name: "Asset ID", key: "assetId", selectedByDefault: true, width: 200,
            component: (row) => {
                return <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <Link style={{ color: "#46535d", paddingLeft: 3 }} href={`/assets/detail/${row?.id}`}
                        target="_blank">{row?.assetId}</Link>
                </div>
            }
        },
        { name: "Client", key: "client", requireAdmin: true, selectedByDefault: true },
        { name: "Current Location", key: "lotId", values: lots, selectedByDefault: true, width: 200 },
        { name: "Vehicle Type", key: "vehicleTypeId", values: vehicleTypes, selectedByDefault: true, width: 200 },
        { name: "Last In", key: "formattedDateIn", sortKey: "dateIn", selectedByDefault: true, sortable: true },
        { name: "Last Out", key: "formattedDateOut", sortKey: "dateOut", selectedByDefault: false, sortable: true, },
        {
            name: "Status", key: "vehicleStatusId", values: vehicleStatuses, selectedByDefault: true,
            component: (row) => {
                return <VehicleStatusView value={row.vehicleStatusId} clientId={row?.clientId} />
            }
        },
        { name: "Registration", key: "registration", selectedByDefault: false, width: 200 },
        { name: "License Plate", key: "licensePlate", selectedByDefault: false, width: 200 },
        { name: "Year", key: "year", selectedByDefault: false, },
        { name: "Make", key: "make", selectedByDefault: false, },
        { name: "Model", key: "model", selectedByDefault: false, },
        { name: "Mileage", key: "mileage", selectedByDefault: false, },
        { name: "Domicile Lot", key: "domicileLot", values: lots, selectedByDefault: false },
        { name: "Telematics", key: "lastGpsLocationFormatted", selectedByDefault: false },
    ];

    const formatDates = (date, lotId) => {
        return FormatService.formatDateNoConversion(lotLookup?.[lotId]?.ianaTimeZone ? FormatService.formatDateByTimeZone(date, lotLookup?.[lotId]?.ianaTimeZone) : date)
    }

    const updateAssetProperties = (data) => {
        return data?.map(v => ({
            ...v,
            domicileLot: readinessByVehicleId?.[v.id]?.domicileLot,
            latestLoadId: readinessByVehicleId?.[v.id]?.latestLoadId,
            latestLoadStatus: readinessByVehicleId?.[v.id]?.latestLoadStatus,
            subMarketIds: subMarketsForLot?.[v.lotId],
            client: getClientName(v.customerId),
            formattedDateIn: formatDates(v?.dateIn, v.lotId),
            dateIn: v?.dateIn,
            formattedDateOut: formatDates(v?.dateOut, v.lotId),
            dateOut: v?.dateOut,
            readinessStatus: readinessByVehicleId?.[v.id] ?? {},
            registration: (v.registrationMonth && v.registrationYear) ? (("0" + v.registrationMonth).slice(-2) + ' ' + v.registrationYear + (v.registrationExpired ? ' (Expired)' : '')) : '',
            licensePlate: v.hasLicensePlate == false ? 'No License Plate' : (v.licensePlate && v.licensePlateState) ? (v.licensePlate + ' ' + v.licensePlateState + (getLPTypeName(v.licensePlateType) != null ? ' (' + getLPTypeName(v.licensePlateType) + ')' : '')) : '',
            licensePlateState: v.hasLicensePlate == false ? '' : v.licensePlateState,
            licensePlateType: v.hasLicensePlate == false ? '' : v.licensePlateType,
            flaggedStr: v.latestFlag ? 'Yes' : 'No',
            lastGpsLocationFormatted: v.lastGpsLocation ? `${v.lastGpsLocation?.lat} | ${v.lastGpsLocation?.long}` : ''
        }));
    };

    vehiclesData = updateAssetProperties(vehiclesData);

    const columnsToAdd = {}

    vehiclesData?.map(v => {
        v.extendedDetails?.map(d => {
            v['extended_' + d.vehicleDetailExtendedTypeId] = d.value;
            if (!excludedTypes.includes(d.vehicleDetailExtendedTypeId) && d.vehicleDetailExtendedTypeId && !columnsToAdd['extended_' + d.vehicleDetailExtendedTypeId]) {
                columnsToAdd['extended_' + d.vehicleDetailExtendedTypeId] = {
                    name: d.label,
                    key: 'extended_' + d.vehicleDetailExtendedTypeId,
                    selectedByDefault: false
                }
            }
        })
        return v;
    })

    vehicleColumns = [...vehicleColumns, ...Object.values(columnsToAdd)]

    const vehicleLookup = arrayToObject(vehiclesData);

    if (canSeeFlags) {
        vehicleColumns.unshift({
            name: '',
            key: 'latestFlag',
            width: 80,
            selectedByDefault: true,
            customizable: false,
            component: (row) => {
                return <FlagAsset
                    iconOnly
                    selectedRows={[row?.id]}
                    flagOn={vehicleLookup?.[row?.id]?.latestFlag}
                    flagObj={vehicleLookup?.[row?.id]?.latestFlag}
                    sourceType={vehicleLookup?.[row?.id]?.latestFlag?.sourceType}
                    getFlagFromApi={false}
                    allData={vehiclesData}
                    entityType={FlagEntityTypes.ASSET}
                    readonly={!isAuthorized(permissionProfiles.ASSETS.ASSETS_PAGE_FLAG_ADD_REMOVE)}
                />
            }
        });
    }

    if (!isAdmin && !isAccountManager) {
        vehicleColumns = vehicleColumns.filter(c => !c.requireAdmin);
    }

    if (isAdmin || (isClient && isUsingTransports)) {
        vehicleColumns.push({
            width: 100,
            name: "Load",
            key: "latestLoadId",
            selectedByDefault: true,
            component: (row) => <>{row?.latestLoadId && <LoadButton latestLoadID={row?.latestLoadId} />}</>
        },);
    }
    if (isAdmin || (isClient && (usingReadiness))) {
        vehicleColumns.push({
            name: "Readiness", width: 350, key: "readinessStatus", selectedByDefault: true, component: (row) => {
                return <AssetReadinessStatuses readinessStatus={row?.readinessStatus} showActivityList={true} />
            }
        });
    }

    const [assetToEdit, setAssetToEdit] = useState(null);
    const [assetToAdd, setAssetToAdd] = useState(null);

    const viewVehicle = (id) => {
        history.push(`/assets/detail/${id}`);
    };

    const onEdit = id => {
        let vehicle = vehiclesData.find(v => v.id === id);
        setAssetToEdit(vehicle);
    };

    const onAdd = () => {
        const clientAccounts = _.flatten(clients.map(c => c.clientAccounts));
        let asset = {};
        asset.vehicleTypeId = first(vehicleTypes)?.id;
        asset.vehicleStatusId = first(vehicleStatuses)?.id;
        asset.lotId = first(lots)?.id;
        asset.customerId = first(clientAccounts)?.id;
        setAssetToAdd(asset);
    };

    const reloadAssets = () => {
        setAssetToEdit(null);
        setAssetToAdd(null);
        refetchVehicles();
    };

    const getRedinnesToExport = (readinessStatus) => {
        let result = '';
        const clientConfig = clientsWithConfigs?.find(c => c.id == readinessStatus.clientId);
        const isUsingManualReadiness = clientConfig?.config?.readinessConfig?.readinessType == readinessTypes.MANUAL;

        let readinessValues = isUsingManualReadiness ? clientConfig?.readinessOptions?.map(opt => {
            const vehicleReadiness = readinessStatus?.readinessValues?.find(r => r.readinessConfigId === opt.id);
            const readinessStatusForVehicle = vehicleReadiness?.status ?? readinessStatuses.RED;
            return {
                ...vehicleReadiness,
                status: readinessStatusForVehicle,
                text: opt?.[`statusText${readinessStatusForVehicle}`]
            }
        }) : [
            ...(readinessStatus?.washStatus ? [{
                status: readinessStatus?.washStatus ?? readinessStatus.RED,
                text: '  Washed'
            }] : []),
            ...(readinessStatus?.ameritInspectionStatus ? [{
                status: readinessStatus?.ameritInspectionStatus ?? readinessStatus.RED,
                text: '  Inspected'
            }] : [])
        ];

        readinessValues?.map((opt, index) =>
            result += (index === 0) ? opt.text : (`( ${opt.text} )`)
        )

        result += (readinessStatus.status) ? (readinessStatus.status == readinessStatuses.GREEN ? ' (Ready)' : ' (Not ready)') : '';
        return result;
    }

    useEffect(() => {
        let filterField = history?.location?.state?.params?.filters;
        if (filterField) {
            for (let i in filterField) {
                updateFilters(i, filterField[i]);
            }
        }
    }, [])

    const onDropFilter = (filterValue, prop) => {
        updateFilters(prop, filterValue);
    };

    const updateFilters = (prop, value) => {
        setPage(1);
        if (prop == 'domicileLot') {
            if (value) {
                setIncludeHistory(true);
            }
        }
        const newFilters = { ...filters, [prop]: { value } }
        dispatch(setFilters(newFilters));
    }

    const searchVehicles = value => {
        setPage(1);
        dispatch(setSearchVal(value.trim()));
    }

    const updateAllFilters = (newFilters) => {
        setPage(1);
        if (newFilters?.['domicileLot']?.value) {
            setIncludeHistory(true);
        }
        dispatch(setFilters(newFilters));
    }

    const getKeysNumber = (vehicle) => {
        let result = '';
        let object = vehicle?.extendedDetails?.find(x => x.vehicleDetailExtendedTypeId == 18);
        if (object) return object?.value ?? result;
        return result;
    }

    const getFobsNumber = (vehicle) => {
        let result = '';
        let object = vehicle?.extendedDetails?.find(x => x.vehicleDetailExtendedTypeId == 19);
        if (object) return object?.value ?? result;
        return result;
    }

    const getLastStatusLoad = (vehicle) => {
        let loadsCopy = [...loads];
        let load = loadsCopy.find((load) => load.id == vehicle.latestLoadId);
        if (load) {
            const latestStatus = load?.latestStatusObject ?? {};
            return statuses[latestStatus.status]?.label;
        }
        return '';
    }

    const getReportRequestByReportType = async (reportType, data) => {
        if (reportType == 2) {
            const allDataMap = data.map((v) => (
                {
                    ID: v.id,
                    VIN: v.descriptor,
                    ["AssetID"]: v.assetId,
                    Client: v.client,
                    "CurrentLocation": getLotName(v.lotId),
                    "DomicileLot": getLotName(v.domicileLot),
                    Year: v.year,
                    Make: v.make,
                    Model: v.model,
                    Trim: v.trim,
                    Mileage: v.mileage,
                    "LastIn": v.formattedDateIn,
                    "LastOut": v.formattedDateOut,
                    Status: getStatusName(v.vehicleStatusId, v.clientId),
                    "Registration": v.registration,
                    "LicensePlate": v.licensePlate,
                    "ConditionReport": v.conditionReportUrl,
                    "AssetType": vehicleTypes?.find(vt => vt.id == v.vehicleTypeId)?.name,
                    "Readiness": getRedinnesToExport(v.readinessStatus),
                    "Load": getLastStatusLoad(v),
                    "LicensePlateNumber": v.licensePlate?.replace(`${v.licensePlateState}`, '')?.replace(`(${getLPTypeName(v.licensePlateType)})`, ''),
                    "LicensePlateState": v.licensePlateState,
                    "LicensePlateType": getLPTypeName(v.licensePlateType),
                    "Key": getKeysNumber(v),
                    "Fob": getFobsNumber(v),
                    lastGpsLocationFormatted: v.lastGpsLocationFormatted
                }
            ));
            return allDataMap;
        } else if (reportType == 1) {
            const allDataMap = data.map((v) => (
                {
                    ID: v.id,
                    VIN: v.descriptor,
                    "AssetType": vehicleTypes?.find(vt => vt.id == v.vehicleTypeId)?.name,
                    ["AssetID"]: v.assetId,
                    Status: getStatusName(v.vehicleStatusId, v.clientId),
                    "CurrentLocation": getLotName(v.lotId),
                }
            ));
            return allDataMap;
        } else if (reportType == 3) {
            const allDataMap = data.map((v) => (
                {
                    Id: v.id,
                    VIN: v.descriptor,
                    ["AssetID"]: v.assetId,
                    Client: v.client,
                    "CurrentLocation": getLotName(v.lotId),
                    "DomicileLot": getLotName(v.domicileLot),
                    Year: v.year,
                    Make: v.make,
                    Model: v.model,
                    Trim: v.trim,
                    Mileage: v.mileage,
                    "LastIn": v.formattedDateIn,
                    "LastOut": v.formattedDateOut,
                    Status: getStatusName(v.vehicleStatusId, v.clientId),
                    "Registration": v.registration,
                    "LicensePlate": v.licensePlate,
                    "ConditionReport": v.conditionReportUrl,
                    "AssetType": vehicleTypes?.find(vt => vt.id == v.vehicleTypeId)?.name,
                    "Readiness": getRedinnesToExport(v.readinessStatus),
                    "Load": getLastStatusLoad(v),
                    "LicensePlateNumber": v.licensePlate?.replace(`${v.licensePlateState}`, '')?.replace(`(${getLPTypeName(v.licensePlateType)})`, ''),
                    "LicensePlateState": v.licensePlateState,
                    "LicensePlateType": getLPTypeName(v.licensePlateType),
                    "Key": getKeysNumber(v),
                    "Fob": getFobsNumber(v),
                    lastGpsLocationFormatted: v.lastGpsLocationFormatted
                }
            ));
            return allDataMap;
        }
    }

    function downloadBase64File(contentType, base64Data, fileName) {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const customExportReportEvent = async (reportType, data) => {
        NotificationProvider.success("Your report is being created, it will be downloaded automatically in a moment. \n You can also see the status of each report in the My Reports section");
        let csvData = await getReportRequestByReportType(parseInt(reportType), data);
        let requestData = {
            ReportType: parseInt(reportType),
            JSON: JSON.stringify(csvData)
        };

        let result = await addNewReport(requestData);
        if (result && !result.error) {
            downloadBase64File(result?.data?.type, result?.data?.content, result?.data?.name);
        } else {
            let concatenateError = result?.error?.data?.messages[0];
            NotificationProvider.error(concatenateError);
        }
    }

    return (
        <div
            className="Vehicles">
            <Authorize profile={permissionProfiles.ASSETS.ASSETS_PAGE_VIEW_ASSET_TABLE}>
                <VehicleTable
                    vehiclesData={vehiclesData}
                    columns={vehicleColumns}
                    isLoading={isLoading()}
                    assetToEdit={assetToEdit}
                    setAssetToEdit={setAssetToEdit}
                    assetToAdd={assetToAdd}
                    setAssetToAdd={setAssetToAdd}
                    updateAsset={reloadAssets}
                    addAsset={reloadAssets}
                    includeHistory={includeHistory}
                    setIncludeHistory={setIncludeHistory}
                    onAdd={onAdd}
                    onEdit={onEdit}
                    viewVehicle={viewVehicle}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    searchVal={searchVal}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    searchVehicles={searchVehicles}
                    sortData={sortData}
                    sortOptions={sortOptions}
                    updateAllFilters={updateAllFilters}
                    customExportChild={customExportReportEvent}
                    canSeeFlags={canSeeFlags}
                // mapExportData={mapExportData}
                //page={page}
                //totalRows={vehicleSearchData?.totalRows}
                // getDataForExport={getDataForExport}
                />
            </Authorize>
        </div>
    );
};

export default VehiclesPage
