import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { orderBy } from "lodash";
import { arrayToObject } from "utils/object-util";
import { excludedTypes, inspectionsPresentAndDates, storageTypes, vehicle_detail_extended_type } from "../VehicleDetailExtended/VehicleDetailExtendedTypes";
import { useGetExtendedFieldTypesQuery } from "../vehicleSlice";
import MonthYearPicker from "components/MonthYearPicker/MonthYearPicker";
import moment from "moment";
import DropdownFilter from "components/DropdownFilter";

export const EditExtendedInfo = ({ vehicleDetails, extendedInfo = [], onChangeField }) => {

    let { data: fieldConfig, error: fieldsError, isLoading: isLoadingFields } = useGetExtendedFieldTypesQuery();

    const data = arrayToObject(extendedInfo, 'vehicleDetailExtendedTypeId');

    const vehicleExtendedComponent = (field) => {
        switch (field?.id) {
            case vehicle_detail_extended_type.keys_handed_over:
            case vehicle_detail_extended_type.fobs_handed_over:
            case vehicle_detail_extended_type.tire_tread_reading:
            case vehicle_detail_extended_type.tire_tread_reading_back_left:
            case vehicle_detail_extended_type.tire_tread_reading_back_right:
            case vehicle_detail_extended_type.tire_tread_reading_front_left:
            case vehicle_detail_extended_type.tire_tread_reading_front_right:
            case vehicle_detail_extended_type.check_under_hood:
                return <CustomInput
                    label={field.name}
                    value={data[field.id]?.value}
                    elementType="input"
                    onChange={val => onChangeField(field.id, val)}
                />;
            case vehicle_detail_extended_type.storage_type:
                return <DropdownFilter
                    title='Select storage type'
                    showEmpty
                    value={data[field.id]?.value}
                    values={storageTypes}
                    onSelect={val => onChangeField(field.id, val)}
                />
            case vehicle_detail_extended_type.DOT_last_inspection_date:
            case vehicle_detail_extended_type.last_state_inspection_date:
                return <MonthYearPicker
                    onChange={(value) => {
                        const val = moment(value).format("YYYY-MM");
                        onChangeField(field.id, val);
                    }}
                    value={data[field.id]?.value} />
            default:
                return undefined;
        }
    }


    const defaultComponent = (field) => {
        return <FormControlLabel
            label={field?.name}
            control={<Checkbox
                title={field?.name}
                checked={data[field?.id]?.value == "Yes" ? true : false}
                onChange={(e, value) => onChangeField(field?.id, value, true)}
            />}
        />
    }

    const getField = (f) => {
        const comp = vehicleExtendedComponent(f);
        return ({
            component: comp ?? defaultComponent(f),
            type: comp ? '1' : '2',
            field: f
        });

    }

    const filteredFields = fieldConfig?.filter(f => !excludedTypes.includes(f.id)) ?? [];

    const fieldWithComponent = orderBy(filteredFields?.map(f => {
        return getField(f);
    }), 'type');

    const fieldConfigLookup = arrayToObject(filteredFields);

    const allFields = fieldWithComponent.filter(f => !inspectionsPresentAndDates.includes(f.field.id));
    const inspections = inspectionsPresentAndDates.map(f => getField(fieldConfigLookup[f]));
    // const tiresData = fieldWithComponent.filter(f => tires.includes(f.field.id))

    return (
        <>
            {inspections?.map(f => <Grid item xs={6} container>
                {f.component}
            </Grid>)}
            {allFields?.map(f => <Grid item xs={6} container>
                {f.component}
            </Grid>)}
            {/* {tiresData?.map(f => <Grid item xs={6} container>
                {f.component}
            </Grid>)} */}
        </>
    );
}