import { Button, Grid, Paper, Tooltip } from '@material-ui/core';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { EntityAuditTooltip } from 'components/EntityAudit/EntityAuditTooltip';
import { useGetDriversQuery } from 'features/drivers/driversSlice';
import { FlagEntityTypes } from 'features/flag/flagsConsts';
import { groupBy } from "lodash";
import { useState } from 'react';
import { useSelector } from "react-redux";
import Authorize from '../../../components/Authorize';
import { isAuthorized } from "../../../components/Authorize/authorizeUtil";
import { permissionProfiles } from '../../../components/Authorize/permissionProfiles';
import DropZoneInDialog from '../../../components/DropZoneInDialog';
import LoadingSpinner from '../../../components/LoadingSpinner';
import NotificationProvider from '../../../components/NotificationProvider';
import FormatService from "../../../services/formatService";
import mobileBreakpoint from "../../../utils/mobileBreakpoint";
import { useGetClientsQuery } from "../../clients/clientSlice";
import { useGetLotsQuery } from '../../lots/lotSlice';
import { selectIsAdmin } from "../../user/userSlice";
import { useDocumentConditionReportMutation } from '../ConditionReportSlice';
import { FlagAsset } from '../Flags/FlagAsset';
import { excludedTypes, tires, vehicleExtendedFormatted, vehicle_detail_extended_type } from '../VehicleDetailExtended/VehicleDetailExtendedTypes';
import VehicleStatusView from '../VehicleStatus/VehicleStatusView';
import AssetReadinessStatuses from '../components/AssetReadinessStatuses';
import { useGetConfigurationByVehicleQuery } from "../configurationSlice";
import { useGetReadinessQuery } from '../readiness/readinessSlice';
import { getLPTypeName } from '../vehicleConsts';
import { useGetFuelTypesQuery, useGetVehicleDetailsExtendedQuery, useGetVehicleTypesQuery, useUploadDocumentMutation } from '../vehicleSlice';
import CreateLoadFromAssetDetails from './CreateLoad/CreateLoadFromAssetDetails';
import { vehicleDetailsPageStyle } from "./VehicleDetailsPageStyle";
import VehicleTickets from './VehicleTickets';

const VehicleDetailsSummary = ({ vehicleDetails, minimalSummary = false, onEdit }) => {

    const classes = vehicleDetailsPageStyle();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const isAdmin = useSelector(selectIsAdmin);
    const flagIsReadOnly = !isAuthorized(permissionProfiles.ASSETS.ASSETS_PAGE_FLAG_ADD_REMOVE);

    let [uploaddocument, { isLoading: uploadingDoc, isError, isSuccess }] = useUploadDocumentMutation();
    let [documentConditionReport] = useDocumentConditionReportMutation();
    let { data: drivers, error: driverError, isLoading: isLoadingDrivers } = useGetDriversQuery();

    const driversLookup = drivers?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients, } = useGetClientsQuery(null, { skip: !isAdmin });
    let { data: configuration, error: configurationError, isLoading: isLoadingConfiguration } = useGetConfigurationByVehicleQuery(vehicleDetails?.id);
    let { data: vehiclesReadiness, error: vehiclesReadinessError, isFetching: isLoadingVehiclesReadiness } = useGetReadinessQuery({});
    let { data: fuelTypes, error: fuelTypesError, isLoading: isLoadingFuelTypes, isSuccess: isSuccessFuelTypes } = useGetFuelTypesQuery();
    let { data: vehicleDetailsExtended, error: VehicleDetailExtendedError, isLoading: isLoadingVehicleDetailExtended } = useGetVehicleDetailsExtendedQuery(vehicleDetails?.id);

    const [isDownloading, setIsDownloading] = useState(false);

    clients = clients || [];
    vehicleTypes = vehicleTypes || [];

    const readinessByVehicleId = groupBy(vehiclesReadiness, r => r.vehicleId)

    let vehicleType = vehicleTypes.find(s => s.id === vehicleDetails?.vehicleTypeId);
    let fuelType = fuelTypes?.find(f => f.id == vehicleDetails?.fuelType);

    if (!vehicleDetails) {
        return (
            <div className={classes.noAssetDiv}>
                <h2>This asset does not exist</h2>
            </div>
        )
    }

    const findLot = (lot_id) => {
        return lots?.find(l => l.id === lot_id) || {};
    }

    const uploadDocument = async (file) => {
        const d = new Date();
        let fileBase64 = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            fileBase64 = reader.result.toString().replace(/^data:(.*,)?/, '');
            const res = uploaddocument({
                "id": 0,
                "vehicleId": vehicleDetails?.id,
                "fileUrl": "",
                "filename": file.name,
                "file_type": file.type,
                "createdAt": d.toISOString(),
                "is_Deleted": 0,
                "filedata": fileBase64
            })
        };
    };

    let client = clients?.find(c => c.clientAccounts.find(ca => ca.id === vehicleDetails?.customerId));
    let lot = findLot(vehicleDetails?.lotId);
    lot = lot || {};

    let conditionReportUrl = vehicleDetails?.conditionReportUrl;

    if (vehicleDetails?.conditionReportId) {
        conditionReportUrl = `/assets/condition/${vehicleDetails?.id}`;
    }

    let dateIn = FormatService.formatDateNoConversion(lot.ianaTimeZone ? FormatService.formatDateByTimeZone(vehicleDetails?.dateIn, lot.ianaTimeZone) : vehicleDetails?.dateIn);
    let dateOut = vehicleDetails?.dateOut;
    if (dateOut) {
        dateOut = FormatService.formatDateNoConversion(lot.ianaTimeZone ? FormatService.formatDateByTimeZone(vehicleDetails?.dateOut, lot.ianaTimeZone) : vehicleDetails?.dateOut);
    }

    let driverName = driversLookup?.[vehicleDetails?.driverId]?.firstName && driversLookup?.[vehicleDetails?.driverId]?.lastName ? driversLookup?.[vehicleDetails?.driverId]?.firstName?.trim() + ' ' + driversLookup?.[vehicleDetails?.driverId]?.lastName?.trim() : null

    const onDownloadCR = async () => {
        setIsDownloading(true);
        let downloadEntity = {
            VehicleId: vehicleDetails?.id,
            ClientAccountId: vehicleDetails?.customerId,
            RequestedFrom: "Web"
        };
        let result = await documentConditionReport(downloadEntity);

        if (result && !result.error) {
            downloadBase64File(result?.data?.type, result?.data?.content, result?.data?.name);
        } else {
            let concatenateError = result?.error?.data?.messages[0];
            NotificationProvider.error(concatenateError);
        }

        setIsDownloading(false);
    };

    function downloadBase64File(contentType, base64Data, fileName) {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    let isConditionalReportEnable = configuration?.isConditionalReportEnabled;

    const fields = [
        {
            key: 'client',
            label: 'Client',
            value: client?.name,
            visible: !!client
        },
        {
            key: 'lot',
            label: 'Lot',
            value: lot.name,
        },
        {
            key: 'status',
            label: 'Status',
            value: <VehicleStatusView value={vehicleDetails?.vehicleStatusId} clientId={client?.id} />,
        },
        {
            key: 'make',
            label: 'Make',
            value: vehicleDetails?.make,
        },
        {
            key: 'model',
            label: 'Model',
            value: vehicleDetails?.model,
        },
        {
            key: 'year',
            label: 'Year',
            value: vehicleDetails?.year,
        },
        {
            key: 'type',
            label: 'Type',
            value: vehicleType?.name,
        },
        {
            key: 'assetId',
            label: 'Asset Id',
            value: vehicleDetails?.assetId,
        },
        {
            key: 'licensePlate',
            label: 'License Plate',
            value: vehicleDetails?.hasLicensePlate == false ? 'No License Plate' : `${vehicleDetails?.licensePlate} - ${vehicleDetails?.licensePlateState ?? 'unknown state'} ${getLPTypeName(vehicleDetails.licensePlateType) != null ? '(' + getLPTypeName(vehicleDetails.licensePlateType) + ')' : ''}`,
            visible: vehicleDetails?.hasLicensePlate != null
        },
        {
            key: 'mileage',
            label: 'Mileage',
            value: vehicleDetails?.mileage ? FormatService.formatNumberWithCommas(vehicleDetails?.mileage) : '',
        },
        {
            key: 'domicile',
            label: 'Domicile',
            value: <Tooltip title={vehicleDetails?.domicileLotAutoCalculated ? 'Automatically set' : 'Manually set'}><div>{lots?.find((lot) => lot.id == vehicleDetails.domicileLot)?.name}</div></Tooltip>
        },
        {
            key: 'driverName',
            label: 'Driver name',
            value: driverName,
            visible: !!driverName
        },
        {
            key: 'driverCarrier',
            label: 'Carrier',
            value: vehicleDetails?.driverCarrier,
            visible: !!vehicleDetails?.driverCarrier
        },
        {
            key: 'gasLevel',
            label: 'Gas Level',
            value: vehicleDetails?.gasLevel,
            visible: !!vehicleDetails?.gasLevel
        },
        {
            key: 'fuelType',
            label: 'Fuel type',
            value: fuelType?.name
        }
    ];

    const extendedFields = [];
    const tiresFields = [];
    const registrationFields = [
        {
            key: 'hasRegistration',
            label: 'Has Registration',
            value: !!vehicleDetails?.hasRegistration ? 'Yes' : 'No'
        },
        {
            key: 'registrationExpired',
            label: 'Registration Expired',
            value: !!vehicleDetails?.registrationExpired ? 'Yes' : 'No'
        },
        {
            key: 'registrationYear',
            label: 'Registration Year',
            value: vehicleDetails?.registrationYear
        },
        {
            key: 'registrationMonth',
            label: 'Registration Month',
            value: vehicleDetails?.registrationMonth
        },
        {
            key: 'physicalRegistration',
            label: 'Has Physical Registration',
            value: vehicleDetailsExtended?.find(d => d.vehicleDetailExtendedTypeId == vehicle_detail_extended_type.registration_present)?.value ?? 'No'
        }
    ];

    vehicleDetailsExtended?.map(d => {
        if (!excludedTypes.includes(d.vehicleDetailExtendedTypeId)) {//License plate should not be taken from the vehicle details extended
            if (tires.includes(d.vehicleDetailExtendedTypeId)) {
                tiresFields.push({
                    key: d.vehicleDetailExtendedTypeId,
                    label: d.label,
                    value: d.value
                })
            } else {
                extendedFields.push({
                    key: d.vehicleDetailExtendedTypeId,
                    label: d.label,
                    value: vehicleExtendedFormatted(d)
                })
            }
        }
    });

    return (
        <>
            <Paper className={`${classes.card} ${isMobile ? classes.cardMobile : ""}`} >
                <Grid container alignItems="center" justifyContent="space-between" spacing={2}
                    className={`${classes.titleRow} ${isMobile ? classes.titleRowMobile : ""}`}>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Grid container alignItems='center'>
                                    <img src="/icons/icon-wheel.svg" className={classes.icon} alt="Asset ID" />
                                    <h2 className={`${classes.title} ${isMobile ? classes.titleRowMobile : ""}`}>
                                        {vehicleDetails?.descriptor ? vehicleDetails?.descriptor : vehicleDetails?.assetId ?? vehicleDetails?.licensePlate}
                                    </h2>
                                    {readinessByVehicleId[vehicleDetails.id] &&
                                        <Grid item className={`${classes.detail} ${isMobile && classes.detailMobile}`}>
                                            <AssetReadinessStatuses loading={isLoadingVehiclesReadiness} readinessStatus={readinessByVehicleId[vehicleDetails.id][0]} editable />
                                        </Grid>}

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!minimalSummary && <Grid item>
                        <Grid container spacing={1} justifyContent="flex-end" alignItems='center' >
                            <Grid item>
                                <EntityAuditTooltip entity={vehicleDetails} />
                            </Grid>
                            <Grid item>
                                <FlagAsset
                                    selectedRows={[vehicleDetails?.id]}
                                    allData={[vehicleDetails]}
                                    entityType={FlagEntityTypes.ASSET}
                                    readonly={flagIsReadOnly}
                                />
                            </Grid>
                            {
                                isConditionalReportEnable &&
                                <Authorize profile={permissionProfiles.CONDITION_REPORT.ConditionReport} allowSeamless={true}>
                                    <Grid item alignItems='flex-end'>
                                        <Grid item >
                                            {onDownloadCR &&
                                                <Button className={classes.editButton}
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => onDownloadCR()}
                                                    disabled={isDownloading}>
                                                    {isDownloading ? "Downloading..." : "Download Condition Report"}
                                                </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                </Authorize>
                            }
                            {
                                <Authorize profile={permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_CREATE_LOAD}>
                                    <CreateLoadFromAssetDetails client={client} vehicleDetails={vehicleDetails} lot={lot} />
                                </Authorize>
                            }
                            <Authorize profile={permissionProfiles.ASSETS.ASSETS_PAGE_EDIT}>
                                <Grid item alignItems='flex-end'>
                                    <Grid item >
                                        <Button className={classes.editButton} color="primary" variant="contained" onClick={() => onEdit()}>
                                            Edit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Authorize>
                            <Authorize profile={permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_UPLOAD_DOCUMENT}>
                                <Grid item>
                                    <DropZoneInDialog
                                        labelDialog={'Upload Document'}
                                        buttonLabel={'Upload Document'}
                                        onSave={uploadDocument}
                                        formContent={
                                            <div className={classes.clientWrapper}>

                                            </div>
                                        }
                                        accept={{
                                            'application/pdf': ['.pdf'],
                                            'image/jpg': ['.jpg'],
                                            'image/png': ['.png'],
                                            'image/jpeg': ['.jpeg']
                                        }}
                                        actionButtonLabel="Upload"
                                    />
                                    <LoadingSpinner loading={uploadingDoc} />
                                </Grid>
                            </Authorize>
                            {
                                !!conditionReportUrl && (
                                    <Authorize
                                        profile={permissionProfiles.ASSETS.ASSETS_DETAIL_PAGE_DOWNLOAD_CONDITION_REPORT}>
                                        <Grid item alignItems="flex-end">
                                            <Button color="primary" variant="contained" href={conditionReportUrl}
                                                target="_blank">Condition Report</Button>
                                        </Grid>
                                    </Authorize>
                                )
                            }
                        </Grid>
                    </Grid>}
                </Grid>
                <Grid container spacing={2} className={classes.dateInfoWrapper}>
                    <Grid item>
                        <Grid container className={classes.dateInfo}>
                            <div className={`${classes.detailLabel} ${classes.dateIn}`}>{dateIn ? "Last In" : ""}</div>
                            <div>{dateIn}</div>
                        </Grid>
                    </Grid>
                    {
                        dateOut
                            ? <>
                                <Grid item>
                                    <Grid container className={classes.dateInfo}>
                                        <div className={`${classes.detailLabel} ${classes.dateOut}`}>Last Out:</div>
                                        <div>{dateOut}</div>
                                    </Grid>
                                </Grid>
                            </>
                            : null
                    }
                </Grid>
                {!minimalSummary && <Grid container spacing={2}>
                    {fields?.filter(field => field.visible == undefined || field.visible).map(field => <Grid item xs={3} className={`${classes.detail} ${isMobile && classes.detailMobile}`}>
                        <Grid container>
                            <div className={classes.detailLabel}>{field.label}: </div>
                            <div>{field.value ?? '-'}</div>
                        </Grid>
                    </Grid>)}
                </Grid>}
                {!!extendedFields?.length && !minimalSummary && <Grid className={classes.extendedDataWrapper} container spacing={2}>
                    {extendedFields?.filter(field => field.visible == undefined || field.visible).map(field => <Grid item xs={3} className={`${classes.detail} ${isMobile && classes.detailMobile}`}>
                        <Grid container>
                            <div className={classes.detailLabel}>{field.label}: </div>
                            <div>{field.value ?? 'NA'}</div>
                        </Grid>
                    </Grid>)}
                </Grid>}
                {!!registrationFields?.length && vehicleDetails?.hasRegistration != null && !minimalSummary && <Grid className={classes.extendedDataWrapper} container spacing={2}>
                    {registrationFields?.filter(field => field.visible == undefined || field.visible).map(field => <Grid item xs={3} className={`${classes.detail} ${isMobile && classes.detailMobile}`}>
                        <Grid container>
                            <div className={classes.detailLabel}>{field.label}: </div>
                            <div>{field.value ?? '-'}</div>
                        </Grid>
                    </Grid>)}
                </Grid>}
                {!!tiresFields?.length && !minimalSummary && <Grid className={classes.extendedDataWrapper} container spacing={2}>
                    {tiresFields?.filter(field => field.visible == undefined || field.visible).map(field => <Grid item xs={3} className={`${classes.detail} ${isMobile && classes.detailMobile}`}>
                        <Grid container>
                            <div className={classes.detailLabel}>{field.label}: </div>
                            <div>{field.value ?? '-'}</div>
                        </Grid>
                    </Grid>)}
                </Grid>}
                <VehicleTickets vehicleDetails={vehicleDetails} client={client} />
            </Paper>
        </>
    );
};

export default VehicleDetailsSummary;
