import { Button, Grid, Paper, Select } from '@material-ui/core';
import { ArrowDownwardSharp, ArrowUpwardSharp } from '@material-ui/icons';
import Icon from '@mui/material/Icon';
import BulkActionsToolbar from 'components/DataTable/BulkActionsToolbar';
import TableFilters from 'components/DataTable/TableFilters';
import { SelectedFilters } from 'components/DataTable/TableFilters/SelectedFilters/SelectedFilters';
import TableViewsSelector from 'components/DataTable/TableViews';
import { userPreferencesComponentIds } from 'components/UserPreferences/userPreferencesConsts';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { arrayToObject } from 'utils/object-util';
import DataListHeader from '../../../../components/DataListHeader';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import FilterService, { fieldTypes } from '../../../../services/filterService';
import { useGetBusinessUnitsQuery, useGetClientsWithConfigsQuery } from '../../../clients/clientConfigSlice';
import { useGetClientsQuery } from '../../../clients/clientSlice';
import { useGetLotsQuery } from '../../../lots/lotSlice';
import { useGetTransportersQuery } from '../../../transporter/transporterSlice';
import { selectIsAccountManager, selectIsAdmin, selectIsClient, selectIsOwner, selectUserClientId } from "../../../user/userSlice";
import { useGetReadinessQuery } from '../../../vehicles/readiness/readinessSlice';
import statuses from '../../loadStatusesWithIcons';
import { setFilters, setSearchVal } from '../../loadsFiltersSlice';
import { useGetLoadsGroupedQuery } from '../../loadsSlice';
import BulkPostGroups from '../BulkPostGroups';
import CreateLoadGroup from '../CreateLoadGroup';
import ExportLoads from '../ExportLoads';
import ImportLoads from '../ImportLoads';
import LoadViewSelector from '../LoadViewSelector/LoadViewSelector';
import LoadsList from '../LoadsList';
import { loadsPageStyle } from './LoadsPageStyle';
import { ExpandFiltersPanel } from 'components/DataTable/TableFilters/ExpandFiltersPanel';

function LoadsPage({ }) {

    const classes = loadsPageStyle()
    let [createLoad, setCreateLoad] = useState(false);
    const [selectedViewId, setSelectedViewId] = useState(0)
    const [selectedRows, setSelectedRows] = useState([]);

    const { searchVal, filters, viewArchived } = useSelector(state => state.loadFilters);

    let { data: loads, error: loadListError, isFetching: isLoadingLoads, isFetching: isRefetchingLoads, refetch } = useGetLoadsGroupedQuery({ lmStatus: filters?.lmStatus?.value, archived: viewArchived });
    let { data: vehiclesReadiness, error: vehiclesReadinessError, isLoading: isLoadingVehiclesReadiness } = useGetReadinessQuery({}, { skip: isLoadingLoads });

    const selectMenuStyle = {
        backgroundColor: '#f7f6fb',
        border: 'none',
        color: 'rgba(0, 0, 0, 0.38)',
    };

    const dispatch = useDispatch();
    const isAdmin = useSelector(selectIsAdmin);
    const isAccountManager = useSelector(selectIsAccountManager);
    const isOwner = useSelector(selectIsOwner);
    let user = useSelector(state => state.oidc.user);
    let isUserLoaded = !!user;

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: transporters, error: transportersError, isLoading: isLoadingTransporters } = useGetTransportersQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: (!isAdmin) });
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null, { skip: !isUserLoaded });
    let { data: businessUnits, error: businessUnitsError, isLoading: isLoadingbusinessUnits, isSuccess: businessUnitsSuccess } = useGetBusinessUnitsQuery();

    clientsWithConfigs = clientsWithConfigs || [];
    clients = clients || [];
    lots = lots || [];
    loads = loads || [];

    let userClientId = useSelector(selectUserClientId);
    let isClient = useSelector(selectIsClient);
    const clientConfigs = clientsWithConfigs?.find(c => c.id == userClientId);

    const canSeeFlags = isOwner || isAccountManager || (isClient && clientConfigs?.config?.lotConfig?.seeFlags);

    let tempStatuses = []
    tempStatuses = Object.entries(statuses).map(([key, value]) => ({ name: value.label, id: +key }))

    const readinessForLoadId = {};
    const contactsForLoad = {};

    const clientLookup = arrayToObject(clients, 'id');
    const clientConfigsLookup = arrayToObject(clientsWithConfigs, 'accountId');
    const vehiclesReadinessLookup = arrayToObject(vehiclesReadiness, 'vehicleId');
    const lotsLookup = arrayToObject(lots, 'id');

    let loadsProcessed = loads?.map(load => {
        let loadClientId = clientsWithConfigs.find(c => c.accountId == load.clientAccountId)?.id;
        readinessForLoadId[load.id] = vehiclesReadinessLookup ? vehiclesReadinessLookup[load?.assets?.[0]?.id] : null;
        contactsForLoad[load.id] = {
            pickup: lotsLookup[load?.pickupLocation?.id]?.contacts?.filter(s => !s.clientId || s.clientId == loadClientId),
            delivery: lotsLookup[load?.deliveryLocation?.id]?.contacts?.filter(s => !s.clientId || s.clientId == loadClientId)
        }
        const loadWithData = {
            ...load,
            isReady: readinessForLoadId[load.id]?.status == 1 ? 1 : 2,
            movementType: clientConfigsLookup[load?.clientAccountId]?.loadMoveTypes?.find(l => l.id == load.configMoveType),
            businessUnitName: businessUnits?.find(bu => bu.id == load.businessUnit)?.name,
            clientName: clientLookup[load?.clientId]?.name,
            flaggedStr: load?.latestFlag ? 'Yes' : 'No',
        };
        return loadWithData;
    });

    let lmStatuses = [
        { id: 107, name: "Needs attention" },
        { id: 108, name: "Picked today" },
        { id: 109, name: "Delivered today" },
        { id: 110, name: "Delayed pickups", filterOn: 'isDelayedPickup', filterOnValue: '1' },
        { id: 111, name: "Delayed deliveries", filterOn: 'isDelayedDelivery', filterOnValue: '1' },
        { id: 112, name: "Delayed", filterOn: 'isDelayed', filterOnValue: '1' },
        { id: 113, name: "At Risk", filterOn: 'isAtRisk', filterOnValue: '1' },
        { id: 114, name: "Not Ready", filterOn: 'isReady', filterOnValue: '2' },
    ];

    let allStatuses = [...tempStatuses, ...lmStatuses];

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let dashboardFilters = {}
        let currentFilters = filters;
        setCreateLoad(false);
        for (let [key, value] of params) {
            if (key === "createload") {
                setCreateLoad(true);
                currentFilters = {};
            }
            else {
                if (value !== "undefined") {
                    if (key === "allPickupDates") {
                        let dates = value.split(",");
                        let startDate = new Date(dates[0]);
                        let endDate = new Date(dates[1]);
                        let dateValue = `${startDate?.toLocaleDateString('en-GB')} - ${endDate?.toLocaleDateString('en-GB')}`
                        value = { startDate: startDate, endDate: endDate, dateValue: dateValue }
                    }
                    dashboardFilters[key] = value;
                }
                else {
                    dashboardFilters[key] = ''
                }
            }
        }
        for (let key in dashboardFilters) {
            currentFilters = updateFilters(key, dashboardFilters[key], currentFilters)
        }
    }, [window.location.search]);

    const IsLmStatus = (prop, value) => {
        if (prop === "latestStatus" && ["107", "108", "109"].includes(value)) {
            return true;
        }
        return false;
    }

    let filterConfig = [
        { type: 'text', size: 2, title: 'Client', key: 'clientId', values: clients, requireAdmin: true },
        { type: 'text', size: 2, title: 'Status', key: 'latestStatus', values: allStatuses, specialBehaviorHdl: IsLmStatus },
        { type: fieldTypes.LOT, size: 2, title: 'Pickup Location', key: 'allPickupLocations', values: lots },
        { type: fieldTypes.LOT, size: 2, title: 'Delivery Location', key: 'allDeliveryLocations', values: lots },
        { type: 'dateRange', size: 1.5, title: 'Pickup Date', key: 'allPickupDates', values: [] },
        { type: 'dateRange', size: 1.5, title: 'Delivery Date', key: 'allDeliveryDates', values: [] },
        { type: 'text', size: 2, title: 'Carrier', key: 'allCarriers', values: transporters },
    ];

    if (!isAdmin) {
        filterConfig = filterConfig.filter(f => !f.requireAdmin);
    }

    if (businessUnits?.length) {
        filterConfig.push({
            type: 'text', size: 2, title: 'Business Unit', key: 'businessUnit', values: businessUnits, getValues: () => businessUnits?.filter(b => !filters?.clientId?.value || b.clientId == filters?.clientId?.value)
        })
    }

    if (canSeeFlags) {
        filterConfig.push({
            type: fieldTypes.TEXT,
            size: 2,
            title: 'Flagged',
            key: 'flaggedStr',
            values: [{ id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }]
        });
    }

    const sortValues = [
        { name: "Created Date", id: 2, key: "createdDate" },
        { name: "Pickup Date", id: 1, key: "calcPickupDate" },
        { name: "Last Status Update", id: 3, key: "latestStatusDate" },
    ];

    const [orderby, setOrderBy] = useState("DSC");
    const [selectedSort, setSelectedSort] = useState(sortValues[0].key);

    const sort = (key, data) => {
        const sorted = data.slice().sort((a, b) => {
            return new Date(a[key]).getTime() -
                new Date(b[key]).getTime()
        }
        );
        data = orderby === "ASC" ? sorted : sorted.reverse();
        return data;
    }

    const searchLoads = value => {
        dispatch(setSearchVal(value.trim()));
    }

    const updateFilters = (prop, value, currentFilters) => {
        let newFilter = {}
        newFilter[prop] = { value: Number.isInteger(value) ? value.toString() : value };
        if (IsLmStatus(prop, value)) {
            newFilter['lmStatus'] = { value: value };
        } else if (prop === "latestStatus") {
            newFilter['lmStatus'] = { value: null };
        }
        dispatch(setFilters({ ...currentFilters, ...newFilter }));
        currentFilters = { ...currentFilters, ...newFilter }
        return currentFilters;
    }

    const onDropFilter = (filterValue, prop) => {
        updateFilters(prop, filterValue, filters);
    };

    const updateAllFilters = (newFilters) => {
        dispatch(setFilters(newFilters));
    }

    const onResetFilters = () => {
        dispatch(setFilters({
            clientId: { value: null },
            latestStatus: { value: null },
            pickupLocationId: { value: null },
            deliveryLocationId: { value: null },
            pickupDates: { value: null },
            deliveryDates: { value: null },
            lmStatus: { value: null },
        }));
    }

    const getData = () => {
        let filtersToSave = Object.entries(filters).map(([key, value]) => ({ key: key, value: JSON.stringify(value) }));
        return {
            filters: filtersToSave,
        }
    }

    const toggleRowSelected = (checked, id) => {
        let selected = [...selectedRows]
        const indexToRemove = selected.indexOf(id);
        if (indexToRemove > -1) {
            selected.splice(indexToRemove, 1);
        }
        if (checked) {
            selected.push(id);
        }
        setSelectedRows(selected);
    }

    let filteredLoads = sort(selectedSort, FilterService.filter(filters, searchVal, loadsProcessed, filterConfig.concat({ type: "text", key: "lmStatus", specialBehaviorHdl: (prop, value) => { if (prop === "lmStatus") { return true; } else { return false; } } })));

    const groupLookup = arrayToObject(loadsProcessed);

    return (
        <>
            {createLoad &&
                <>
                    <Paper variant='outlined' className={classes.createLoad}>
                        <CreateLoadGroup />
                    </Paper>
                    <Paper variant='outlined' className={classes.newLoadsTitle}>
                        <h1 className={classes.loadsTitle}>New Loads ({filteredLoads?.length ?? 0})</h1>
                    </Paper>
                </>
            }

            <div className={classes.listContainer}>
                {!createLoad &&
                    <TableFilters
                        tableId={userPreferencesComponentIds.LOADS_PAGE}
                        selectedViewId={selectedViewId}
                        dropDownsConfig={filterConfig}
                        filters={filters}
                        onDropFilter={onDropFilter}
                        onResetFilters={onResetFilters}
                        updateAllFilters={updateAllFilters}
                        useApplyButton={false}
                    />
                }
                <div style={{ flex: 6, marginLeft: createLoad ? '0' : '0.7em' }}>
                    {!createLoad && <DataListHeader
                        title={<Grid container spacing={1}>
                            <ExpandFiltersPanel />
                            <Grid item>{`Loads (${filteredLoads?.length})`}</Grid>
                            <LoadViewSelector />
                        </Grid>}
                        data={loadsProcessed}
                        onSearch={searchLoads}
                        searchVal={searchVal}
                        searchSize={12}
                        actionButtons={
                            <Grid container spacing={1}>
                                <Grid item>
                                    <ImportLoads />
                                </Grid>
                                <Grid item>
                                    <ExportLoads groupedLoads={filteredLoads}
                                        businessUnits={businessUnits}
                                        loads={loads}
                                        isLoadingLoads={isLoadingLoads}
                                        readinessForLoadId={readinessForLoadId} />
                                </Grid>
                            </Grid>}

                    />}
                    <Paper variant='outlined' className={classes.sortWrapper}>
                        {!createLoad && <SelectedFilters
                            className={classes.selectedFiltersWrapper}
                            filters={filters}
                            filterConfig={filterConfig}
                            onClearFilter={(filterKey) => onDropFilter(null, filterKey)}
                            tableId={userPreferencesComponentIds.LOADS_PAGE}
                            selectedViewId={selectedViewId}
                        />}
                        <Grid container direction='row' alignItems="center">
                            <h1 className={classes.title}>{"Sort by:"}</h1>
                            {<Select native
                                title='Sort by:'
                                className={classes.sortSelect}
                                variant='outlined'
                                onChange={(e) => { setSelectedSort(e.target.value) }}
                                defaultValue={sortValues[0]}
                                error={false}
                                disabled={false}
                            >
                                {sortValues.map((value, key) => (
                                    <option id={key} key={key} style={selectMenuStyle} value={value.key}>{value.name}</option>
                                ))}
                            </Select>}
                            <Button variant={orderby == 'ASC' ? "contained" : 'text'} className={orderby == 'ASC' ? classes.selectedSort : ''} onClick={() => setOrderBy("ASC")}><Icon component={ArrowUpwardSharp} /></Button>
                            <Button variant={orderby == 'DSC' ? "contained" : 'text'} className={orderby == 'DSC' ? classes.selectedSort : ''} onClick={() => setOrderBy("DSC")}><Icon component={ArrowDownwardSharp} /></Button>
                            {!createLoad &&
                                <div container className={classes.views}>
                                    <TableViewsSelector
                                        selectedView={selectedViewId}
                                        onSelectView={(val) => setSelectedViewId(val)}
                                        tableId={userPreferencesComponentIds.LOADS_PAGE}
                                        getData={getData}
                                    />
                                </div>
                            }
                        </Grid>
                    </Paper>
                    <LoadsList
                        selectedRows={selectedRows}
                        toggleRowSelected={toggleRowSelected}
                        loads={filteredLoads}
                        readinessForLoads={readinessForLoadId}
                        contactsForLoad={contactsForLoad}
                        reload={refetch}
                        canSeeFlags={canSeeFlags}
                    />
                    {!!selectedRows?.length && <BulkActionsToolbar
                        allData={filteredLoads}
                        selectedRows={selectedRows}
                        actions={[
                            // <LoadStatusUpdate
                            //     btnStyle='none'
                            //     loads={selectedRows?.flatMap(selectedGroups => groupLookup[selectedGroups]?.loads)}
                            // />,
                            <BulkPostGroups
                                btnStyle='none'
                                groups={selectedRows?.map(selectedGroups => groupLookup[selectedGroups])}
                            />
                        ]}
                        onClear={() => setSelectedRows([])}
                    />}
                </div>
            </div>
            <LoadingSpinner loading={isLoadingLoads} />
        </>
    );
}

export default LoadsPage;