import { ReportSectionStyles } from "../ReportSectionStyles";
import { AddElementButton } from './ReportColumn/AddElementButton';
import { ReportColumn } from "./ReportColumn/ReportColumn";
import { AddColumnButton } from "./ReportColumn/AddColumnButton";

export const ReportSection = ({ section, onSectionChange, sectionIndex, onAddColumn, onDeleteColumn }) => {
    const classes = ReportSectionStyles();

    const updateElements = (sectionIndex, columnIndex, newElementList) => {
        onSectionChange(sectionIndex, columnIndex, newElementList.map((q, index) => ({ ...q, order: index })));
    }

    const onAddElement = (sectionIndex, elementType, columnIndex) => {
        updateElements(sectionIndex, columnIndex, [...(section?.columns?.[columnIndex]?.elements ?? []), { elementType: +elementType }]);
    }

    return (
        <div className={classes.reportSection}>
            {section?.columns?.length < 4 &&
                <AddColumnButton
                    columnIndex={-1}
                    onAddColumn={onAddColumn}
                    sectionIndex={sectionIndex} />
            }
            {section?.columns?.map((column, columnIndex) => (
                <>
                    <div className={classes.column}>
                        <AddElementButton
                            onAddElement={onAddElement}
                            sectionIndex={sectionIndex}
                            columnIndex={columnIndex}
                            onDeleteColumn={onDeleteColumn}
                        />

                        <div className={classes.elements}>
                            <ReportColumn
                                columnIndex={columnIndex}
                                elements={column?.elements}
                                sectionIndex={sectionIndex}
                                updateElements={updateElements} />
                        </div>
                    </div>
                    {section?.columns?.length < 4 &&
                        <AddColumnButton
                            columnIndex={columnIndex}
                            onAddColumn={onAddColumn}
                            sectionIndex={sectionIndex} />
                    }
                </>
            ))}
        </div>
    )
}