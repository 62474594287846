import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { useState } from "react";
import { arrayToObject } from "utils/object-util";
import { Question } from "./components/Question";
import { EditFormOutputStyle } from "./style";
import { questionTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { useEffect } from "react";
import { useUpdateFormResponseMutation } from "features/Forms/CustomReport/customReportSlice";
import NotificationProvider from "components/NotificationProvider";

export const EditFormOutput = ({ customCheckin, lotId }) => {
    const classes = EditFormOutputStyle();

    const [updateForm, { isLoading: saving }] = useUpdateFormResponseMutation();

    const [openDialog, setOpenDialog] = useState(false);
    const [formAnswers, setFormAnswers] = useState({});

    useEffect(() => {
        setFormAnswers(answerLookup)
    }, [customCheckin?.answers])

    const handleClose = () => {
        setOpenDialog(false);
        setFormAnswers(answerLookup);
    }

    const handleSave = async () => {
        let result = await updateForm({
            ...customCheckin,
            clientId: customCheckin.clientAccountId,
            answers: Object.values(formAnswers),
            lotId: lotId
        });
        if (result?.error) {
            NotificationProvider.error('Failed to update the form');
        } else {
            NotificationProvider.success('Successfully updated the form');
            handleClose();
        }
    };

    const allQuestions = customCheckin?.form?.sections?.flatMap(section => section.questions)?.filter(q => q.questionType != questionTypes.Signature);
    const answerLookup = arrayToObject(customCheckin?.answers?.filter(q => q.questionType != questionTypes.Signature), 'questionId');
    
    const isSaving = saving;

    const isQuestionValid = (q) => {
        switch (q?.questionType) {
            case questionTypes.Image:
                return (formAnswers?.[q.id]?.pictureObj || formAnswers?.[q.id]?.pictureUrl) ? true : false;
            case questionTypes.MultipleChoice:
                return (formAnswers?.[q.id]?.values && formAnswers?.[q.id]?.values != "[]") ? true : false;
            default:
                return (formAnswers?.[q.id]?.values && formAnswers?.[q.id]?.values != `""`) ? true : false
        }
    }

    const isValid = () => {
        return allQuestions?.every(q => !q.required || isQuestionValid(q))
    }

    return (
        <>
            <Button
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={() => setOpenDialog(true)}>
                Edit
            </Button>
            <Paper variant='outlined' className={classes.formContainer}>
                <Dialog open={openDialog} onClose={() => handleClose()} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth>
                    <DialogTitle id="form-dialog-title">{'Edit Form Output'}</DialogTitle>
                    <DialogContent>
                        <form id="editFormOutput" className={`${classes.form}`}>
                            {allQuestions?.map((q, index) => <>
                                <Question
                                    question={q}
                                    formAnswers={formAnswers}
                                    setFormAnswers={setFormAnswers}
                                />
                            </>)}
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()} color="primary" disabled={isSaving}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSave}
                            color="primary"
                            variant="contained"
                            disabled={isSaving || !isValid()}
                        >
                            {isSaving ? 'Saving...' : 'Save'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </>
    )
}