import {selectIsClient} from "features/user/userSlice";
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useGetDistanceReportMutation} from 'features/lots/lotSlice';
import DropZoneInDialog from 'components/DropZoneInDialog';
import CustomInput from 'components/CustomInput';
import NotificationProvider from 'components/NotificationProvider';
import {useGetClientsQuery} from 'features/clients/clientSlice';
import DistanceReportResults from './DistanceReportResults';
import {distanceReportStyles} from './style';
import ExportButton from 'components/ExportButton/ExportButton';
import {IconDownload} from 'icons';
import {permissionProfiles} from "../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../components/Authorize";

const DistanceReportUpload = ({}) => {
    const classes = distanceReportStyles();

    let [generateReport, {
        data: reportData,
        error: importError,
        isLoading: generatingReport
    }] = useGetDistanceReportMutation();
    let {data: clients, error: clientError, isLoading: isLoadingClients} = useGetClientsQuery(null);

    const [selectedClient, setSelectedClient] = useState('');
    const [showResults, setShowResults] = useState(false);
    let isClient = useSelector(selectIsClient);

    const downloadTemplate = async () => {
    }

    const onSave = async (file) => {
        let fd = new FormData();
        fd.append("uploaded_file", file);
        fd.append("clientId", (isClient || selectedClient === '0') ? null : selectedClient);
        const res = await generateReport(fd);
        if (res.error) {
            NotificationProvider.error("Failed to generate the report");
            NotificationProvider.error(res.error?.data?.[0]);
        } else {
            if (res?.data != []) {

                setShowResults(true);
            }
        }
    }

    const onSelectClient = (val) => {
        if(val === "0") {
            setSelectedClient('0');
        } else {
            setSelectedClient(val);
        }
    }

    return (
        <div>
            <DistanceReportResults results={reportData} open={showResults || generatingReport}
                                   loading={generatingReport} setOpen={setShowResults}/>
            <DropZoneInDialog
                className={classes.btn}
                btnStyle='outlined'
                labelDialog={'Generate Distance Report'}
                buttonLabel={'Generate Distance Report'}
                onSave={onSave}
                formContent={
                    <p>
                        {!isClient && <CustomInput
                            id="client"
                            label="Client"
                            value={selectedClient}
                            elementType="dropdown"
                            onChange={val => onSelectClient(val)}
                            values={[{ id: '0', name: 'All clients' }, ...(clients ?? [])]}
                            showEmpty
                        />}
                        <div className={classes.downloadButton}>
                            <ExportButton
                                label='Download template'
                                fileLabel='distance_report_template'
                                mapExportData={row => row}
                                btnStyle='text'
                                className
                                icon={<IconDownload/>}
                                data={[{
                                    ['Zip']: '12345',
                                    ['Address']: 'Optional',
                                    ['VIN']: 'Optional',
                                    ['Asset ID']: 'Optional',
                                    ['Note']: 'Optional',
                                }]}
                            />
                        </div>
                        {/* <Button
                            color="primary"
                            className={classes.downloadButton}
                            onClick={downloadTemplate}>
                            Download Template
                        </Button> */}
                    </p>}
            />

            {/* <ImportAssetsTemplate
                    open={openTemplateDialog}
                    setOpen={setOpenTemplateDialog}
                /> */}
        </div>
    );
};

export default DistanceReportUpload;
