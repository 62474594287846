import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import PermissionList from 'components/Authorize/permissions/PermissionList/PermissionList';
import FormList from 'features/Forms/FormList';
import IssuesTrackerList from 'features/IssuesTracker/IssuesTrackerList';
import MobileActivityPage from 'features/MobileActivity/MobileActivityPage';
import EditUserTask from 'features/Tasks/components/UserTasks/EditUserTask';
import CameraEventsList from 'features/access/CamerEvents/CameraEventsList';
import ActivityInvoicePage from 'features/invoice/activity/components/ActivityInvoicePage';
import KpiInspection from 'features/landing/components/KpiDashboard/navigations/KpiInspection/KpiInspection';
import OneOnOnesPage from 'features/landing/components/KpiDashboard/navigations/KpiOneOnOne/OneOnOnesPage';
import { ActivityListPage } from 'features/landing/components/MechanicalDashboard/ActivityList/ActivityListPage';
import LoadGroupDetails from 'features/loads/components/LoadGroupDetails';
import LotOverview from 'features/lots/components/LotOverview';
import SecurityPhotos from 'features/lots/components/SecurityPhotos/SecurityPhotos';
import PricingPage from 'features/pricing/PricingPage';
import LotAuditHistoryList from 'features/vehicles/VehicleLotAudit/LotAuditHistoryList';
import VehicleLotAuditHistory from 'features/vehicles/VehicleLotAudit/VehicleLotAuditHistory';
import AuditList from 'features/vehicles/VehicleLotAudit/components/AuditList';
import { IconLibrary } from 'icons/IconLibrary';
import { Route, Switch } from 'react-router-dom';
import ExternalConfirmation from './components/ExternalConfirmation';
import UsersTaskPage from './features/Tasks/components/UserTasks/UsersTaskPage/UsersTaskPage';
import AccessPage from "./features/access/AccessPage";
import DoorReleaseList from './features/access/DoorRelease/DoorReleaseList';
import ContractsPage from './features/clients/contractsPage/ContractsPage';
import InventoryPage from './features/inventory/InventoryPage';
import InvoicePage from './features/invoice/loads/components/InvoicePage/InvoicePage';
import StorageInvoicePage from './features/invoice/storage/components/StorageInvoicePage';
import DashboardPage from './features/landing/DashboardPage';
import LoadDashboard from './features/loads/components/LoadDashboard';
import LoadsPage from './features/loads/components/LoadsPage/LoadsPage';
import CallbackPage from './features/login/CallbackPage';
import LogoutPage from './features/login/LogoutPage';
import PrivateRoute from './features/login/PrivateRoute';
import NewLotInspection from "./features/lotInspection/lotInpecionNew";
import LotsTabs from './features/lots/components/LotsTabs/LotsTabs';
import MePassword from './features/me/password';
import ActivityWorkOrder from "./features/mechanicalWork/ActivityWorkOrder";
// import ReportsPage from "./features/reporting/ReportsPage";
import SubscriptionPage from './features/subscriptions/SubscriptionPage';
import TransporterPage from "./features/transporter/TransporterPage";
import UserPage from './features/user/components/UserPage';
import ChangePasswordPage from "./features/users/components/editUser/changePassword/changePasswordPage";
import ConditionReportPage from "./features/vehicles/ConditionReportPage";
import VehiclesFileUploadPage from "./features/vehicles/FileUploadPage";
import VehicleDetailsPage from './features/vehicles/VehicleDetailsPage';
import VehicleLotAudit from './features/vehicles/VehicleLotAudit';
import VehiclesPage from './features/vehicles/VehiclesPage';
import IssueTracker from 'features/IssuesTracker/IssueTracker';
import AuthorizeTokenView from 'components/Authorize/AuthorizeTokenView';
import ReportsPage from 'features/invoice/reports/reportsPage/ReportsPage';
import UserManagementPage from "./features/users/UserManagement";
import UsersPage from "./features/users/components/UsersPage";
import CostCodesPage from 'features/costCodes/CostCodesPage';
import { WorkflowPage } from 'features/IssuesTracker/WorkflowPage/WorkflowPage';
import KpiDashboardPage from 'features/landing/components/KpiDashboard/KpiDashboardPage';

const routes = () => (
    <Switch>
        <PrivateRoute path="/logout" component={LogoutPage} />
        <Route path="/changePassword/:userId" component={ChangePasswordPage} />
        <PrivateRoute path="/forms" component={FormList} profile={permissionProfiles.FORMS.CREATE_FORMS} pageTitle='Forms' />
        <PrivateRoute path="/resetpassword" component={MePassword} pageTitle='Reset Password' />
        <PrivateRoute path="/workorder/:id" component={ActivityWorkOrder} pageTitle='Workorder' />
        <PrivateRoute path="/lot/securityPhotos/:id" component={SecurityPhotos} pageTitle='Security Photos' />
        <PrivateRoute path="/permissions" component={PermissionList} pageTitle='Permissions' />
        <Route path="/confirm" component={ExternalConfirmation} />
        <PrivateRoute path="/authorize" component={AuthorizeTokenView} />
        <PrivateRoute path="/subscriptions" component={SubscriptionPage} />
        <PrivateRoute path="/user" component={UserPage} pageTitle='Users' />
        <PrivateRoute path="/transporters" component={TransporterPage} />
        <PrivateRoute path="/slas" component={ContractsPage} pageTitle='SLA' />
        <PrivateRoute path="/lots/overview/:id" component={LotOverview} pageTitle='Lot Overview' />
        <PrivateRoute path="/lots" component={LotsTabs} pageTitle='Lots' />
        <PrivateRoute path="/assets/fileUpload" component={VehiclesFileUploadPage} />
        <PrivateRoute path="/assets/detail/:id" component={VehicleDetailsPage} />
        <PrivateRoute path="/assets/condition/:id" component={ConditionReportPage} />
        <PrivateRoute path="/assets/audit/:auditId/history/:id" component={VehicleLotAuditHistory} pageTitle='Audit' />
        <PrivateRoute path="/assets/audit/:auditId/history" component={LotAuditHistoryList} pageTitle='Audit History' />
        <PrivateRoute path="/assets/audit/:id" component={VehicleLotAudit} pageTitle='Audit Results' />
        <PrivateRoute path="/assets/audit" component={AuditList} pageTitle='Audit List' />
        <PrivateRoute path="/assets" component={VehiclesPage} pageTitle='Assets'profile={permissionProfiles.ASSETS.ASSETS_PAGE_VIEW_ASSET_TABLE} />
        <PrivateRoute path="/load/group/:id" component={LoadGroupDetails} pageTitle='Load' />
        <PrivateRoute path="/loads/list" component={LoadsPage} pageTitle='Loads' />
        <PrivateRoute path="/loads" component={LoadDashboard} pageTitle='Load Dashboard' />
        {/* <PrivateRoute path="/reports" component={ReportsPage} /> */}
        <Route path="/callback" component={CallbackPage} />
        <PrivateRoute path="/access" component={AccessPage} pageTitle='Access' />
        <PrivateRoute path="/doorRelease" component={DoorReleaseList} profile={permissionProfiles.ACCESS.DOOR_RELEASE_LIST} pageTitle='Door Releases' />
        <PrivateRoute path="/cameras" component={CameraEventsList} profile={permissionProfiles.ACCESS.CAMERA_EVENTS_LIST} pageTitle='Camera Events' />
        <PrivateRoute path="/dashboard/:type" component={DashboardPage} pageTitle='Dashboard' />
        <PrivateRoute path="/dashboard" component={DashboardPage} pageTitle='Workorder Dashboard' />
        <PrivateRoute path="/mechanical/:status" component={ActivityListPage} pageTitle='Workorders' />
        <PrivateRoute path="/invoices/loads/new/:loadId" component={InvoicePage} pageTitle='New Load Invoice' profile={permissionProfiles.INVOICE.INVOICES_VIEW}/>
        <PrivateRoute path="/invoices/loads" component={InvoicePage} pageTitle='Load Invoices' profile={permissionProfiles.INVOICE.INVOICES_VIEW}/>
        <PrivateRoute path="/invoices/storage" component={StorageInvoicePage} pageTitle='Storage Invoices' profile={permissionProfiles.INVOICE.INVOICES_VIEW}/>
        <PrivateRoute path="/invoices/activity" component={ActivityInvoicePage} pageTitle='Activity Invoices' profile={permissionProfiles.INVOICE.INVOICES_VIEW}/>
        <PrivateRoute path="/storage" component={ReportsPage} pageTitle='Storage Reports' />
        <PrivateRoute path="/costcodes" component={CostCodesPage} profile={permissionProfiles.REPORTING.VIEW_COST_CODES_PAGE} pageTitle='Cost Codes' />
        <PrivateRoute path="/users" component={UsersPage} />
        <PrivateRoute path="/userManagement" component={UserManagementPage} profile={permissionProfiles.USER_MANAGEMENT.ACCESS} pageTitle='User Management' />
        <PrivateRoute path="/oneonones" component={OneOnOnesPage} pageTitle='One on Ones' />
        <PrivateRoute path="/oneonones/new" component={OneOnOnesPage} pageTitle='New One on One' />
        <PrivateRoute path="/workflow/:view" component={WorkflowPage} profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_VIEW} pageTitle='Tickets' />
        <PrivateRoute path="/workflow" component={WorkflowPage} profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_VIEW} pageTitle='Tickets' />
        <PrivateRoute path="/ticket/:id" component={IssueTracker} profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_VIEW} pageTitle='Ticket' />
        <PrivateRoute path="/ticket" component={IssuesTrackerList} profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_VIEW} pageTitle='Ticket List' />
        <PrivateRoute path="/inspection/:id" component={NewLotInspection} pageTitle='Inspection' />
        <PrivateRoute path="/inspection" component={KpiInspection} profile={permissionProfiles.INSPECTION.INSPECTION_ACCESS} pageTitle='Inspection List' />
        <PrivateRoute path="/inspection/new" component={NewLotInspection} pageTitle='New Inspection' />
        <PrivateRoute path="/tasks" component={UsersTaskPage} pageTitle='User Tasks' />
        <PrivateRoute path="/mobile/activity" component={MobileActivityPage} pageTitle='Mobile Activity' />
        <PrivateRoute path="/pricing" component={PricingPage} profile={permissionProfiles.PRICING.MANAGE_PPRICING} pageTitle='Pricing Database' />
        <PrivateRoute path="/inventory" component={InventoryPage} profile={permissionProfiles.INVENTORY.INVENTORY_ACCESS} pageTitle='Inventory' />
        <PrivateRoute path="/usertask/:id" component={EditUserTask} pageTitle='User Task' />
        <PrivateRoute path="/iconlibrary" component={IconLibrary} pageTitle='Icon Library' />
        <PrivateRoute path="/" component={DashboardPage} />
    </Switch>
);

export default routes;
