import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import CustomInput from 'components/CustomInput';
import LotsDropdown from 'components/SharedDropdowns/LotsDropDown';
import { setNewLots } from 'features/loads/loadsStateSlice';
import { LotTypeEnum } from 'features/lots/LotsEnums';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { forwardRef, useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import { useDispatch, useSelector } from 'react-redux';
import { usStates } from 'services/usStates';
import { v4 as uuidv4 } from 'uuid';
import LocationFieldStyle from './LocationFieldStyle';
import LocationSchedule from './LocationSchedule';

const useStyles = LocationFieldStyle;

const LocationField = ({ clientId, allowNewLots, name, title, onChange, newLoad, onChangeValue, errors, loadToEdit, setNewLot, showMandatoryError, newLot = false, onChangeLocation = () => { } }) => {

    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    lots = lots || [];

    const classes = useStyles();

    const { newLots } = useSelector(state => state.loadState);
    const dispatch = useDispatch();

    const newLotsList = newLots?.map(lot => { return { ...lot, name: `${lot?.name} (new)` } }) || []
    lots = [...lots, ...newLotsList];

    const onValueChange = (key, val) => {
        onChange(name.concat(key), val)
        let newLot = newLots?.find(lot => lot.id === newLoad?.[`${name}NewLotId`]);
        let lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1)
        newLot = { ...newLot, [lowerCaseKey]: val }
        let newLotsFiltered = newLots?.filter(lot => lot?.id !== newLoad?.[`${name}NewLotId`]);
        newLotsFiltered = [...newLotsFiltered, newLot];
        dispatch(setNewLots(newLotsFiltered));
    }

    const onLotTypeChange = (newLot) => {
        setNewLot(newLot);
        if (newLot) {
            if (newLoad?.[`${name}NewLotId`]) {

            } else {
                let uuid = uuidv4()
                dispatch(setNewLots([...newLots, { id: uuid }]));
                // onValueChange(`${name}NewLotId`, uuid) //TODO check if changes both newLot and newLotId
                onChangeValue({ [`${name}NewLotId`]: uuid, [`${name}NewLot`]: newLot })
            }
        }
    }

    const onLotNameChange = (val) => {
        onChangeValue({ [`${name}Name`]: val, [`${name}LocationId`]: 0 });
        let newLot = newLots?.find(lot => lot.id === newLoad?.[`${name}NewLotId`]);
        newLot = { ...newLot, ["name"]: val }
        let newLotsFiltered = newLots?.filter(lot => lot?.id !== newLoad?.[`${name}NewLotId`]);
        newLotsFiltered = [...newLotsFiltered, newLot];
        dispatch(setNewLots(newLotsFiltered));
    }

    const [lotAddress, setLotAddress] = useState();
    const [lotToEdit, setLotToEdit] = useState();

    const onLotChange = (val) => {
        populateLotAddressAndSchedule(val)
        onChangeLocation();
        onChangeValue({ [`${name}LocationId`]: val, [`${name}Name`]: null, [`pickupStartDate`]: null, [`deliveryStartDate`]: null });
    }

    const populateLotAddressAndSchedule = (lotId) => {
        let lot = lots?.find(l => l?.id == lotId)
        let address = `Address: ${lot?.addressLine1 || lot?.address} ${lot?.addressLine2 || ""}, City: ${lot?.city || ""}, State: ${lot?.state || ""}, Zip: ${lot?.zipCode || lot?.zip || ""}, Phone: ${lot?.phoneNumber || lot?.phone || ""}`
        setLotAddress(lotId ? address : null);
        setLotToEdit(lotId ? { ...lot } : null);
    }

    useEffect(() => {
        if (!newLot) populateLotAddressAndSchedule(newLoad?.[`${name}LocationId`]);
    }, [newLoad?.[`${name}LocationId`]])

    const [existingLots, setExistingLots] = useState();

    const onZipChange = (val) => {
        let existingLots = lots.filter(l => l.zipCode == val)
        setExistingLots(existingLots)
        onValueChange("Zip", val)
    }

    const onExistingLotSelect = (lotId) => {
        onLotChange(lotId)
        setNewLot(false)
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickSchedule = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <form className={classes.lot} id={`${name}LocationField`}>
            <Grid container>
                {!newLot &&
                    <Grid item xs={loadToEdit ? 12 : 8}>
                        <div className={classes.input}>
                            <LotsDropdown
                                clientId={clientId}
                                title={`${title} Location Name`}
                                value={newLoad?.[`${name}LocationId`]}
                                onSelect={val => onLotChange(val)}
                                values={lots}
                                showEmpty
                                required={!newLot}
                                valid={!(showMandatoryError && !newLoad?.[`${name}LocationId`])}
                                touched
                                newLots={newLotsList}
                            />
                        </div>
                    </Grid>}
                {newLot &&
                    <Grid item xs={8}>
                        <div className={classes.input}>
                            <CustomInput
                                label={`${title} Location Name`}
                                value={newLoad?.[`${name}Name`]}
                                elementType="input"
                                onChange={onLotNameChange}
                                values={lots}
                                showEmpty
                                required={newLot}
                                valid={!(showMandatoryError && !newLoad?.[`${name}Name`])}
                                touched
                                clientId={clientId}
                                newLots={newLotsList}
                            />
                        </div>
                    </Grid>}
                {!loadToEdit && allowNewLots &&
                    <Grid item xs={4} className={classes.radioContainer}>
                        <div className={classes.input}>
                            <label className={classes.radioLable}>
                                <input className={classes.radioButton} type="radio" checked={!newLot} onChange={() => onLotTypeChange(!newLot)} />
                                Existing Lot
                            </label>
                            <label className={classes.radioLable}>
                                <input className={classes.radioButton} type="radio" checked={newLot} onChange={() => onLotTypeChange(!newLot)} />
                                New Lot
                            </label>
                        </div>
                    </Grid>
                }
            </Grid>

            {
                <div className={classes.lotAddress}>
                    {!newLot && <>{lotAddress} </>}
                    {((newLoad?.[`${name}Name`])?.length > 0 || newLoad?.[`${name}LocationId`] > 0) ? <div>
                        <Button onClick={handleClickSchedule} className={classes.scheduleButton}>
                            Lot Schedule
                        </Button>
                        <LocationSchedule
                            schedules={newLoad?.[`${name}Schedule`] || []}
                            lotToEdit={lotToEdit}
                            newLot={newLot}
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            onValueChange={onValueChange}
                        />
                    </div> : <></>}
                </div>
            }
            {
                newLot &&
                <div>
                    <Grid container>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    label="Node Name"
                                    value={newLoad?.[`${name}NodeName`]}
                                    elementType="input"
                                    onChange={val => onValueChange("NodeName", val)}
                                    disabled={!newLot}
                                    required={newLot}
                                    valid={!(showMandatoryError && !newLoad?.[`${name}NodeName`])}
                                    touched
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    label="Address"
                                    value={newLoad?.[`${name}Address`]}
                                    elementType="input"
                                    onChange={val => onValueChange("Address", val)}
                                    disabled={!newLot}
                                    required={newLot}
                                    valid={!(showMandatoryError && !newLoad?.[`${name}Address`])}
                                    touched
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    label="City"
                                    value={newLoad?.[`${name}City`]}
                                    elementType="input"
                                    onChange={val => onValueChange("City", val)}
                                    disabled={!newLot}
                                    required={newLot}
                                    valid={!(showMandatoryError && !newLoad?.[`${name}City`])}
                                    touched
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    label="State"
                                    value={newLoad?.[`${name}State`]}
                                    elementType="dropdown"
                                    onChange={val => onValueChange("State", val)}
                                    disabled={!newLot}
                                    values={usStates}
                                    showEmpty
                                    required={newLot}
                                    valid={!(showMandatoryError && !newLoad?.[`${name}State`])}
                                    touched
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    label="Zip"
                                    value={newLoad?.[`${name}Zip`] ?? ''}
                                    elementType="input"
                                    onChange={val => onZipChange(val)}
                                    disabled={!newLot}
                                    required={newLot}
                                    valid={!(showMandatoryError && !newLoad?.[`${name}Zip`])}
                                    touched
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.phoneInput}>
                                <Input
                                    variant="outlined"
                                    defaultCountry="US"
                                    value={newLoad?.[`${name}Phone`]}
                                    defaultValue={newLoad?.[`${name}Phone`]}
                                    onChange={val => onValueChange("Phone", val)}
                                    error={(newLoad?.[`${name}Phone`] && !isValidPhoneNumber(newLoad?.[`${name}Phone`])) || (showMandatoryError && !newLoad?.[`${name}Phone`])}
                                    label="POC Phone"
                                    disabled={!newLot}
                                    required={newLot}
                                    fullWidth
                                    InputLabelProps={{ className: classes.label }}
                                    inputComponent={phoneInput}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }
            {newLot && <Grid container>
                <Grid item xs={6}>
                    <div className={classes.input}>
                        <CustomInput
                            label="POC Name"
                            value={newLoad?.[`${name}PocName`]}
                            elementType="input"
                            onChange={val => onValueChange("PocName", val)}

                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.input}>
                        <CustomInput
                            label="POC Email"
                            value={newLoad?.[`${name}PocEmail`]}
                            elementType="input"
                            onChange={val => onValueChange("PocEmail", val)}
                            valid={(!errors[`${name}PocEmail`]) && !(showMandatoryError && !newLoad?.[`${name}PocEmail`])}
                            required={newLot}
                            touched

                        />
                    </div>
                </Grid>
                <div >
                    <FormControlLabel
                        control={<Checkbox
                            className={classes.checkBox}
                            checked={newLoad?.[`${name}Type`] === LotTypeEnum.EXTERNAL_SERVICE}
                            onChange={(e, value) => onValueChange("Type", value ? LotTypeEnum.EXTERNAL_SERVICE : LotTypeEnum.STANDARD)}
                        />}
                        label="External Service" />
                </div>
            </Grid>}
            {/* <div className={classes.input}>
                      <CustomInput
                          label="Pick-Up POC Phone"
                          value={newLoad?.[`${name}PocPhone`]}
                          elementType="input"
                          onChange={val => onValueChange("PocPhone", val)}
                      />
                  </div> */}
            {
                existingLots?.length > 0 && newLot &&
                <div className={classes.existingLotsContainer}>
                    <div className={classes.existingLotsTitle}>There are lots with the same zip code...</div>
                    {existingLots.map(lot =>
                        <FormControlLabel
                            control={<Checkbox
                                checked={newLoad?.[`${name}LocationId`] === lot.id && !newLot}
                                onChange={() => onExistingLotSelect(lot.id)}
                            />}
                            label={`Name: ${lot?.name}, Address: ${lot?.addressLine1 || lot?.address || ""} ${lot?.addressLine2 || ""} ${lot?.city || ""} ${lot?.state || ""} ${lot?.zipCode || ""}`} />
                    )}
                </div>
            }
        </form >
    );
};

export default LocationField;

const phoneInput = forwardRef((props, ref) => {

    return (
        <TextField
            {...props}
            inputRef={ref}
            variant='outlined'
            name='phone'
        />
    )
})

