import FormatService from "services/formatService";

export const vehicle_detail_extended_type = {
    bill_of_landing: 1,
    registration_present: 2,
    license_plate: 3,
    DOT_inspection_sticker_present: 4,
    check_engine_light: 5,
    other_dash_light: 6,
    AC_Heat_Radio_working: 7,
    battery_issue: 8,
    low_tire_pressure: 9,
    tire_tread_reading: 10,
    check_spare: 11,
    brake_noise: 12,
    tire_damage: 13,
    windshield_damage: 14,
    body_damage: 15,
    damage_other: 16,
    MISC: 17,
    keys_handed_over: 18,
    fobs_handed_over: 19,
    DOT_last_inspection_date: 20,
    tire_tread_reading_front_left: 21,
    tire_tread_reading_front_right: 22,
    tire_tread_reading_back_left: 23,
    tire_tread_reading_back_right: 24,
    check_under_hood: 25,
    vehicle_title: 26,
    physical_bill_of_landing: 28,
    last_state_inspection_date: 29,
    state_inspection_present: 30,
    storage_type: 31
}

export const excludedTypes = [
    //vehicle_detail_extended_type.bill_of_landing,
    vehicle_detail_extended_type.registration_present,
    vehicle_detail_extended_type.license_plate,
    //vehicle_detail_extended_type.DOT_inspection_sticker_present,
    vehicle_detail_extended_type.MISC,
    vehicle_detail_extended_type.tire_damage,
    // vehicle_detail_extended_type.windshield_damage,
    vehicle_detail_extended_type.tire_damage,
    vehicle_detail_extended_type.damage_other,
    vehicle_detail_extended_type.tire_tread_reading,
]


export const tires = [
    vehicle_detail_extended_type.tire_tread_reading,
    vehicle_detail_extended_type.tire_tread_reading_back_left,
    vehicle_detail_extended_type.tire_tread_reading_back_right,
    vehicle_detail_extended_type.tire_tread_reading_front_left,
    vehicle_detail_extended_type.tire_tread_reading_front_right
];

export const inspectionsPresentAndDates = [
    vehicle_detail_extended_type.DOT_inspection_sticker_present,
    vehicle_detail_extended_type.DOT_last_inspection_date,
    vehicle_detail_extended_type.state_inspection_present,
    vehicle_detail_extended_type.last_state_inspection_date,
];

export const storageTypes = [
    { id: 'storage_new', name: "New vehicle" },
    { id: 'storage_used', name: "Used vehicle" },
    { id: 'storage_merchants', name: "Used vehicle pending a merchant pickup" }
]

export const vehicleExtendedFormatted = (field) => {
    switch (field?.vehicleDetailExtendedTypeId) {
        case vehicle_detail_extended_type.DOT_last_inspection_date:
        case vehicle_detail_extended_type.last_state_inspection_date:
            return `${FormatService.formatToMonthAndYear(field?.value)}`
        case vehicle_detail_extended_type.storage_type:
            return storageTypes?.find(s => s.id === field?.value)?.name ?? field?.value
        default:
            return field?.value;
    }
}

