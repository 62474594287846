import { Grid } from "@material-ui/core";
import { Alert } from "@mui/material";
import NotificationProvider from "components/NotificationProvider";
import ShimmerLoader from "components/ShimmerLoader/ShimmerLoader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormActions from "./FormActions/FormActions";
import FormHeader from "./FormHeader/FormHeader";
import FormSections from "./FormQuestions/FormSections";
import { FormVersions } from "./FormVersions/FormVersions";
import { emptyForm, formStatuses } from "./formBuilderConsts";
import { useCreateFormMutation, useGetFormByTypeForClientQuery } from "./formBuilderSlice";
import { FormBuilderStyles } from "./style";

const FormBuilder = () => {
    const classes = FormBuilderStyles();

    const { clientId, formType } = useSelector(state => state.formState);

    const [form, setForm] = useState(emptyForm(formType));
    const [openVersions, setOpenVersions] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState();
    const [changeStack, setChangeStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    const [saveForm, { isLoading: saving }] = useCreateFormMutation();
    let { data: formFromApi, error: formError, isFetching: isLoadingForms } = useGetFormByTypeForClientQuery({ clientId, formType, version: selectedVersion }, { skip: !formType });

    useEffect(() => {
        if (!isLoadingForms) {
            const empty = emptyForm(formType);
            setForm(formFromApi?.id ? formFromApi : empty);
            setChangeStack([]);
            setRedoStack([]);
        }
    }, [isLoadingForms, formFromApi]);

    useEffect(() => {
        setSelectedVersion();
    }, [clientId, formType]);

    const updateForm = (newForm) => {
        setChangeStack([form, ...changeStack]);
        setForm(newForm);
        setRedoStack([])
    }

    const updateSections = (newSectionList) => {
        updateForm({ ...form, ['sections']: newSectionList?.map((q, index) => ({ ...q, order: index })) });
    }

    const onFormChange = (fieldName, value) => {
        updateForm({ ...form, [fieldName]: value });
    }

    const redo = () => {
        const lastVersion = redoStack[0];
        if (lastVersion) {
            updateForm(lastVersion)
        }
    }

    const undo = () => {
        const lastVersion = changeStack[0];
        changeStack.splice(0, 1);
        if (lastVersion) {
            setRedoStack([form]);
            setForm(lastVersion);
        }
    }

    const onAddSection = () => {
        updateSections([...(form.sections ?? []), {}]);
        scrollToEndOfPage();
    }


    const scrollToEndOfPage = () => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }

    const onSaveForm = async (saveAsDraft) => {
        let result = await saveForm({
            ...form,
            clientId,
            formStatus: saveAsDraft ? formStatuses.Draft : formStatuses.Published,
            sections: form.sections?.map(section => (
                { ...section, questions: section.questions?.map(q => ({ ...q, values: JSON.stringify(q.values) ?? "" })) }
            ))
        });
        if (result && !result.error) {
            NotificationProvider.success("Form version saved successfully");
        } else {
            NotificationProvider.error("Failed to save form");
        }
        setSelectedVersion();
        return result;
    }

    return (
        <div className={classes.formBuilderWrapper}>
            {isLoadingForms ?
                <div className={classes.loaderWrapper}>
                    <ShimmerLoader count={10} height="120" />
                </div> : <>
                    <Grid container spacing={2} className={classes.formBuilderContainer}>
                        <Grid item xs={12}>
                            <Alert severity='info' className={classes.alert}>
                                When using a custom check-in form, the user will be prompted to select a client and enter the VIN before filling out the custom form.
                            </Alert>
                        </Grid>
                        <Grid item xs={openVersions ? 9 : 12}>
                            <FormHeader
                                form={form}
                                onFormFieldChange={onFormChange}
                                formType={formType}
                                clientId={clientId}
                            />
                            <FormSections
                                form={form}
                                onFormFieldChange={onFormChange}
                            />
                        </Grid>
                        {openVersions && <Grid item xs={3}>
                            <FormVersions
                                onSelectVersion={(v) => setSelectedVersion(v)}
                                formType={formType}
                                clientId={clientId}
                                onClose={() => setOpenVersions(false)}
                            /></Grid>}
                    </Grid>
                    <FormActions
                        onAddSection={onAddSection}
                        onSave={onSaveForm}
                        saving={saving}
                        undo={undo}
                        redo={redo}
                        redoEnabled={!!redoStack?.length}
                        undoEnabled={!!changeStack?.length}
                        openVersions={() => setOpenVersions(!openVersions)}
                        selectedVersion={selectedVersion}
                        formType={formType}
                        clientId={clientId}
                    />
                </>}
        </div>
    );
}
export default FormBuilder;