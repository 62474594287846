import { makeStyles } from "@material-ui/core";

export const editAssetStyle = makeStyles((theme) => ({
    vinWrappper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    vinInput: {
        position: 'relative'
    },
    formWrapper: {
        padding: '2em'
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: theme.palette.common.white
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100'
    },
    addPhoto: {
        marginTop: 20
    },
    error: {
        textAlign: "center",
        fontSize: 18,
        color: theme.palette.error.main,
        maxWidth: 500,
        margin: "0 auto 16px auto"
    },
    hiddenButtons: {
        visibility: "hidden",
        opacity: 0
    },
    button: {
        margin: "16px 8px",
        width: '210px'
    },
    input: {
        position: 'relative',
    }
}));