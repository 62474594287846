import { Grid } from '@material-ui/core';
import React from "react";
import CustomInput from "../../../../../components/CustomInput";
import { useGetInvoiceAccountsQuery, useGetInvoiceItemsQuery } from "../../loadInvoiceSlice";
import { invoiceDetailsStyle } from "./InvoiceDetailsStyle";

export const EditService = ({ onFieldChange, serviceToEdit, index = null }) => {

    let { data: invoiceItems, error, isFetching } = useGetInvoiceItemsQuery({});
    let { data: invoiceAccounts, error: accountsError, isFetching: accountsFetching } = useGetInvoiceAccountsQuery({});

    const classes = invoiceDetailsStyle();
    return (
        <div className={classes.service}>

            <Grid container spacing={2} >
                <Grid item xs={3} className={classes.label}>
                    <CustomInput
                        //label='Description'
                        value={serviceToEdit?.description}
                        onChange={(val) => onFieldChange('description', val, index)}
                        required={true}
                        elementType="input"
                    />
                </Grid>
                <Grid item xs={3} >
                    <CustomInput
                        // label='Product/Service (invoice)'
                        value={serviceToEdit?.quickbooksItemId}
                        onChange={(val) => onFieldChange('quickbooksItemId', val, index)}
                        elementType="dropdown"
                        values={invoiceItems?.map(i => ({ ...i, name: i.fullyQualifiedName }))}
                        required={true}
                        showEmpty
                    />
                </Grid>
                <Grid item xs={3} >
                    <CustomInput
                        // label='Category (bill)'
                        value={serviceToEdit?.quickbooksAccountId}
                        onChange={(val) => onFieldChange('quickbooksAccountId', val, index)}
                        elementType="dropdown"
                        values={invoiceAccounts?.map(i => ({ ...i, name: i.fullyQualifiedName }))}
                        required={true}
                        showEmpty
                    />
                </Grid>
                <Grid item xs={3} >
                    <CustomInput
                        // label='Amount'
                        value={serviceToEdit?.amount}
                        onChange={(val) => onFieldChange('amount', val, index)}
                        elementType="input"
                        required={true}
                        numberOnly
                    />
                </Grid>
                {/* <Grid item xs={2} >
                                <Checkbox
                                    checked={serviceToEdit?.passThroughs}
                                    onChange={(e, val) => onFieldChange('passThroughs', val)} />
                            </Grid> */}
            </Grid>
        </div>
    )
}

