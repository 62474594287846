import { Tab, Tabs } from "@material-ui/core";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import { useGetConfigurationByVehicleQuery } from "features/vehicles/configurationSlice";

export const vehicleTabTypes = {
    DETAILS: 0,
    ACCESS: 1,
    CHECK_IN: 2
}



export const VehicleTabs = ({ vehicleId, selected = 0, setSelected }) => {

    const vehicleTabs = [
        {
            label: 'Asset Details',
        },
        {
            label: 'Access Groups',
            permission: permissionProfiles.ACCESS.MANAGE_ACCESS
        }

    ]


    let { data: configuration, error: configurationError, isLoading: isLoadingConfiguration } = useGetConfigurationByVehicleQuery(vehicleId);

    if (configuration?.useCustomCheckin) {
        vehicleTabs.push({
            label: 'Check In',
        });
    }

    return (
        <TabsWrapper tabs={vehicleTabs} selectedTab={selected} setSelectedTab={setSelected} />
    )
}