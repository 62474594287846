import { Grid, Paper } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VinDecode from "features/vehicles/VinDecode/VinDecodePopup";
import VinMatching from "features/vehicles/components/VinMatching/VinMatching";
import { useGetFuelTypesQuery, useGetVehicleTypesQuery } from "features/vehicles/vehicleSlice";
import { useGetBusinessUnitsQuery, useGetClientsWithConfigsQuery } from "features/clients/clientConfigSlice";
import Validator from "services/validator";
import CustomInput from "components/CustomInput";
import { selectIsClient, selectUserClientId } from "features/user/userSlice";
import GroupAssetFormStyles from "./GroupAssetFormStyles";
import { setErrors } from "features/loads/loadsStateSlice";

const useStyles = GroupAssetFormStyles;

const GroupAssetForm = ({ loadGroup, setLoadGroup, clientBUs, setClientBUs, disableAssetDetails, legIndex }) => {

    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes, isSuccess: isVehicleTypesSuccess } = useGetVehicleTypesQuery();
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);
    let { data: fuelTypes, error: fuelTypesError, isLoading: isLoadingFuelTypes, isSuccess: isSuccessFuelTypes } = useGetFuelTypesQuery();
    let { data: businessUnits, error: businessUnitsError, isLoading: isLoadingbusinessUnits, isSuccess: businessUnitsSuccess } = useGetBusinessUnitsQuery();

    vehicleTypes = vehicleTypes || [];
    vehicleTypes = vehicleTypes.filter(v => [5, 7, 8, 16, 53].includes(v.id));//FIXME!!!
    clientsWithConfigs = clientsWithConfigs || [];

    const isClient = useSelector(selectIsClient);
    const loggedInUserClientId = useSelector(selectUserClientId);
    const { showMandatoryError, errors } = useSelector(state => state.loadState);

    const dispatch = useDispatch();

    const classes = useStyles();
    const [startTimer, setStartTimer] = useState(false);
    const [decodedData, setDecodedData] = useState({});
    const [colorDecoded, setColorDecoded] = useState(false);
    const [openDecoded, setOpenDecoded] = useState(false);

    const isLoading = () => isLoadingVehicleTypes || isLoadingClientsWithConfigs;

    const getErrorMessagesForField = {
        'vin': (val) => {
            if (!Validator.validateVin(val)) {
                return "Invalid VIN";
            }
            return false;
        },
        'year': (val) => {
            if (!(val && val > 1980 && val.length <= 4)) {
                return "Invalid year";
            }
            return false;
        },
    }

    const clearErrorForFields = (keys) => {
        const newErrors = { ...errors };
        keys.map(key => {
            if (newErrors[key]) delete newErrors[key];
        })
        dispatch(setErrors(newErrors));
    };

    const onChange = (key, val, groupLevelField) => {
        const errorMessage = getErrorMessagesForField[key] && getErrorMessagesForField[key](val)
        if (errorMessage) {
            dispatch(setErrors({ ...errors, [key]: errorMessage }))
        }
        else {
            clearErrorForFields([key]);
        }
        if (groupLevelField) {
            setLoadGroup({ ...loadGroup, [key]: val });
        } else {
            updateLoad({ [key]: val });
        }

    };

    const updateLoad = (values) => {
        let loads = [...loadGroup?.loads?.map(l => ({ ...l }))];
        if (loads) {
            loads[legIndex] = { ...loads?.[legIndex], ...values }
            setLoadGroup({ ...loadGroup, loads });
        }
    }

    const populateDecoded = () => {
        setOpenDecoded(false);
        if (decodedData) {
            setColorDecoded(true);
            /* if (decodedData?.vehicleType)
                 setColorDecodedType(true);*/

            updateLoad({
                'year': decodedData?.year,
                'make': decodedData?.make,
                'model': decodedData?.model
            })
            // setLoadGroup({
            //     ...loadGroup,
            //     'year': decodedData?.year,
            //     'make': decodedData?.make,
            //     'model': decodedData?.model,
            // });
            setTimeout(() => {
                setColorDecoded(false);
            }, 5000);
        }
    }

    useEffect(() => {
        if (startTimer) {
            setTimeout(() => {
                setStartTimer(false);
            }, 4000);
        }
    }, [startTimer]);

    useEffect(() => {
        if (businessUnits?.length) {
            let clientId = isClient ? loggedInUserClientId : loadGroup?.clientId;
            setClientBUs(businessUnits?.filter(b => b.clientId == clientId));
        }
    }, [loadGroup?.clientId, businessUnits, clientsWithConfigs])

    let filteredClients = clientsWithConfigs.filter(client => client.config.transportConfig.useTransport == true)

    let form = (
        <Grid direction='row' container >
            <Grid item xs={12}>
                {!loadGroup.id && loadGroup?.loads?.length > 1 &&
                    <div className={classes.input}>
                        <CustomInput
                            elementType='text'
                            value={loadGroup?.groupName}
                            label='Group Name'
                            onChange={(val) => onChange('groupName', val, true)}
                        />
                    </div>}
                <Grid container>
                    {!loadGroup.id && !isClient &&
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="clientId"
                                    label="Client"
                                    value={loadGroup?.clientId}
                                    elementType="dropdown"
                                    onChange={val => onChange("clientId", +val, true)}
                                    values={filteredClients}
                                    showEmpty
                                    required
                                    touched
                                    valid={!(showMandatoryError && !loadGroup?.clientId)}
                                    disabled={disableAssetDetails && !!loadGroup?.clientId}
                                />
                            </div>
                        </Grid>}

                    {!!clientBUs?.length && <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="businessUnit"
                                label="Business Unit"
                                value={loadGroup?.businessUnit}
                                elementType="dropdown"
                                onChange={val => onChange("businessUnit", +val, true)}
                                values={clientBUs}
                                valid={!(showMandatoryError && !loadGroup?.businessUnit)}
                                showEmpty
                                required
                                touched
                            />
                        </div>
                    </Grid>}
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item xs={9}>
                                    <CustomInput
                                        id="vin"
                                        label="VIN"
                                        //value={loadGroup?.vin ?? ''}
                                        value={loadGroup?.loads?.[legIndex]?.vin ?? ""}
                                        elementType="input"
                                        onChange={val => onChange('vin', val)}
                                        required
                                        //valid={!errors['vin'] && !(showMandatoryError && !loadGroup?.vin)}
                                        valid={!errors['vin'] && !(showMandatoryError && !loadGroup?.loads?.[legIndex]?.vin)}
                                        touched
                                        disabled={disableAssetDetails && !!loadGroup?.loads?.[legIndex]?.vin}
                                    />
                                </Grid>
                                <Grid item >
                                    <VinDecode vin={loadGroup?.loads?.[legIndex]?.vin} setDecodedData={setDecodedData} applyDecode={populateDecoded} openDecoded={openDecoded} setOpenDecoded={setOpenDecoded} />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="assetId"
                                label="Asset ID"
                                //value={loadGroup?.assetId ?? ''}
                                value={loadGroup?.loads?.[legIndex]?.assetId ?? ""}
                                elementType="input"
                                onChange={val => onChange("assetId", val)}
                                required
                                //valid={!(showMandatoryError && !loadGroup?.assetId)}
                                valid={!(showMandatoryError && !loadGroup?.loads?.[legIndex]?.assetId)}
                                touched
                                disabled={disableAssetDetails && !!loadGroup?.loads?.[legIndex]?.assetId}
                            />
                        </div>
                    </Grid>

                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <div className={classes.input}>
                            <CustomInput
                                id="make"
                                label="Vehicle Make"
                                //value={loadGroup?.make ?? ''}
                                value={loadGroup?.loads?.[legIndex]?.make ?? ""}
                                elementType="input"
                                onChange={val => onChange("make", val)}
                                required
                                //valid={!(showMandatoryError && !loadGroup?.make)}
                                valid={!(showMandatoryError && !loadGroup?.loads?.[legIndex]?.make)}
                                touched
                                colorInput={colorDecoded}
                            />
                            {decodedData && <VinMatching decodedData={decodedData.make} editedData={loadGroup?.make} field='make' />}
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.input}>
                            <CustomInput
                                id="model"
                                label="Vehicle Model"
                                //value={loadGroup?.model ?? ''}
                                value={loadGroup?.loads?.[legIndex]?.model ?? ""}
                                elementType="input"
                                onChange={val => onChange("model", val)}
                                required
                                //valid={!(showMandatoryError && !loadGroup?.model)}
                                valid={!(showMandatoryError && !loadGroup?.loads?.[legIndex]?.model)}
                                touched
                                colorInput={colorDecoded}
                            />
                            {decodedData && <VinMatching decodedData={decodedData.model} editedData={loadGroup?.model} field='model' />}
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.input}>
                            <CustomInput
                                id="year"
                                label="Vehicle Year"
                                //value={loadGroup?.year ?? ''}
                                value={loadGroup?.loads?.[legIndex]?.year ?? ""}
                                elementType="input"
                                onChange={val => onChange("year", val)}
                                required
                                //valid={!errors['year'] && !(showMandatoryError && !loadGroup?.year)}
                                valid={!errors['year'] && !(showMandatoryError && !loadGroup?.loads?.[legIndex]?.year)}
                                touched
                                numberOnly
                                colorInput={colorDecoded}
                            />
                            {decodedData && <VinMatching decodedData={decodedData.year} editedData={loadGroup?.year} field='year' />}
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="assetTypeId"
                                label="Vehicle Type"
                                //value={loadGroup?.assetTypeId}
                                value={loadGroup?.loads?.[legIndex]?.assetTypeId ?? ""}
                                elementType="dropdown"
                                onChange={val => onChange("assetTypeId", +val)}
                                values={vehicleTypes}
                                showEmpty
                                required
                                //valid={!(showMandatoryError && !loadGroup?.assetTypeId)}
                                valid={!(showMandatoryError && !loadGroup?.loads?.[legIndex]?.assetTypeId)}
                                touched
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="vehicleFuelTypeId"
                                label="Vehicle Fuel Type"
                                //value={loadGroup?.vehicleFuelTypeId}
                                value={loadGroup?.loads?.[legIndex]?.vehicleFuelTypeId ?? ""}
                                elementType="dropdown"
                                onChange={val => onChange("vehicleFuelTypeId", +val)}
                                values={fuelTypes}
                                showEmpty
                                required
                                //valid={!(showMandatoryError && !loadGroup?.vehicleFuelTypeId)}
                                valid={!(showMandatoryError && !loadGroup?.loads?.[legIndex]?.vehicleFuelTypeId)}
                                touched
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );


    return (
        <Paper className={classes.formContainer}>
            <form id="createLoadForm" className={`${classes.form} ${isLoading() && classes.disabledForm}`}>
                {form}
            </form>
        </Paper>
    );
};

export default GroupAssetForm;
