import { createSlice } from "@reduxjs/toolkit";

export const vehicleFiltersSlice = createSlice({
    name: 'vehicleFilters',
    initialState: {
        searchVal: '',
        filters: {
            clientId: { value: null },
            lotId: { value: null },
            vehicleTypeId: { value: null },
            dateIn: { value: null },
            dateOut: { value: null },
            vehicleStatusId: { value: null },
        },
        filtersSetFromOtherScreen: false
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        mergeSavedFilter: (state, action) => {
            // const newFilters = state.filters;
            // Object.keys(action.payload).map(filterKey => {
            //     if (!state.filters?.[filterKey]?.value?.length) {
            //         newFilters[filterKey] = action.payload[filterKey];
            //     }
            // });
            // state.filters = newFilters;
            if (Object.values(state.filters).every(value => !value?.value?.length)) {
                state.filters = action.payload;
            }
        },
        setStatuses: (state, action) => {
            state.filters.vehicleStatusId = { value: action.payload }
        },
        setLot: (state, action) => {
            state.filters.lotId = { value: action.payload + "" };
        },
        setFiltersSetFromOtherScreen: (state, action) => {
            state.filtersSetFromOtherScreen = action.payload;
        }
    },
});

export const { setSearchVal, setFilters, setStatuses, setLot, mergeSavedFilter, setFiltersSetFromOtherScreen } = vehicleFiltersSlice.actions;
export default vehicleFiltersSlice.reducer;
