import { Grid, IconButton, TextField } from "@material-ui/core";
import { indexOf, orderBy } from "lodash";
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { FormBuilderStyles } from "../../style";
import { questionTypes } from "../../formBuilderConsts";
import { useGetVehicleTypesQuery } from "features/vehicles/vehicleSlice";
import { ListFieldMapping } from "./ListFieldMapping";

const QuestionValues = ({
    form,
    question,
    onFieldChange
}) => {

    const classes = FormBuilderStyles();

    const values = orderBy([...question?.values ?? [], { value: '', isNew: true, order: question?.values?.length }] ?? [{}], 'order');

    const getValues = () => {
        return values.filter(v => v.value);
    }

    const onValueChange = (newVal, index, valToMap) => {
        let valuesToSave = getValues();
        if (index == values?.length - 1) {
            valuesToSave = values;//Saving the new option
            const { isNew, ...others } = valuesToSave[index];
            valuesToSave[index] = others;
        }
        valuesToSave[index] = { value: newVal, valToMap: valToMap };
        onFieldChange('values', valuesToSave?.map((v, index) => ({ ...v, order: index })));
    }

    // const onAddValue = () => {
    //     onFieldChange('values', [...values, {}]);
    // }

    const onDeleteValue = (rowIndex) => {
        const valuesToSave = getValues();
        valuesToSave.splice(rowIndex, 1);
        onFieldChange('values', valuesToSave?.map((v, index) => ({ ...v, order: index })));
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const valuesToSave = getValues();
        onFieldChange('values', arrayMove(valuesToSave, oldIndex, newIndex).map((q, index) => ({ ...q, order: index })))
    };

    const mappedToListField = question.questionType === questionTypes.Dropdown &&
        question.mappedToFieldType && question.mappedToField


    return (
        <Grid direction="column" spacing={1} container className={classes.optionsContainer} >
            <div className={classes.optionsContainerTitle}>Options</div>
            <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                {values.map((v, index) => (
                    <SortableItem index={index} option={v} rowIndex={index} onValueChange={onValueChange} onDeleteValue={onDeleteValue} mappedToListField={mappedToListField} />
                ))}
            </SortableContainer>
            {/* <Button variant='outlined' onClick={onAddValue}>Add option</Button> */}
        </Grid>
    );
}
export default QuestionValues;



const SortableItem = sortableElement(({ option, rowIndex, onValueChange, onDeleteValue, mappedToListField }) => {
    const classes = FormBuilderStyles();
    const DragHandle = sortableHandle(() => <DragIndicatorIcon className={classes.dragIcon} color="secondary" />);
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();

    return (
        <Grid item container alignItems="center" className={classes.multiValueOpt}>
            <Grid item>
                {option?.isNew ? <div style={{ width: '1.8em' }}></div> : <DragHandle />}
                <TextField
                    value={option?.value ?? ""}
                    elementType="input"
                    variant="standard"
                    onChange={e => {
                        onValueChange(e.target.value, rowIndex, option?.valToMap)
                    }}
                    placeholder={option?.isNew ? '+ Type a new option...' : undefined}
                />
                {!option?.isNew &&
                    <IconButton onClick={() => onDeleteValue(rowIndex)}>
                        <HighlightOffOutlinedIcon style={{ width: '0.7em' }} />
                    </IconButton>}
            </Grid>
            <Grid item>
                {!option?.isNew && mappedToListField &&
                    //TODO: Make generic
                    <ListFieldMapping
                        value={option?.valToMap}
                        onChangeVal={val => onValueChange(option?.value ?? "", rowIndex, +val)}
                        values={vehicleTypes}
                    />}
            </Grid>
        </Grid>
    )
});

const SortableContainer = sortableContainer(({ children }) => {
    return <Grid container direction="column" >
        {children}
    </Grid>;
});