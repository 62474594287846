import FormOutput from "features/Forms/FormOutput/FormOutput";
import { useGetCustomCheckinQuery } from "../vehicleSlice";
import { vehicleDetailsPageStyle } from "../VehicleDetailsPage/VehicleDetailsPageStyle";
import { Grid } from "@material-ui/core";
import { EditFormOutput } from "features/Forms/FormOutput/EditFormOutput/EditFormOutput";

export const VehicleCustomCheckIn = ({ vehicle }) => {
    const classes = vehicleDetailsPageStyle();
    let { data: customCheckin, error: formError, isLoading: isLoadingForms } = useGetCustomCheckinQuery(vehicle?.id);

    return (
        <>
            <FormOutput
                loading={isLoadingForms}
                fileName={`checkin-${vehicle?.descriptor}.pdf`}
                headerContent={<Grid container alignItems='center' justifyContent="space-between" style={{ padding: '1em' }}>
                    <h2 className={`${classes.title}`}>Check-In For Asset: {vehicle?.descriptor ?? vehicle?.assetId}</h2>
                    <Grid item ><EditFormOutput customCheckin={customCheckin} lotId={vehicle.lotId}/></Grid>
                </Grid>}
                {...customCheckin}
            />
        </>
    )
}