import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { clientConfigSetup } from '../../clientConfigsSetup';
import ContractCard from '../ContractCard';
import ContractsListStyle from './ContractsListStyle';

const useStyles = ContractsListStyle;

const ContractsList = ({ clientId, clientsWithConfigs }) => {

    const classes = useStyles();

    const [clientConfig, setClientConfig] = useState()

    useEffect(() => {
        if (clientId && clientsWithConfigs) {
            let a = clientsWithConfigs.find(c => c.id == clientId)
            setClientConfig(a)
        }
    }, [clientId, clientsWithConfigs])

    const allContracts = clientConfigSetup();

    return (
        <>
            {clientConfig &&
                <div>
                    <div className={classes.title}>Select type of SLA</div>
                    <Grid container>
                        {allContracts.map((contract, index) =>
                            <Grid key={index} item xs={6}>
                                <ContractCard contract={contract} clientConfig={clientConfig} setClientConfig={setClientConfig} />
                            </Grid>)}
                    </Grid>
                </div>
            }
        </>
    );
};

export default ContractsList;


